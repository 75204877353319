import { useState, useRef, useEffect } from "react";
import {Text} from 'react-native';
import "../../App.css";
import supabase from "../../config/supabaseClient";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import { TextField, Box } from "@mui/material";
import { styles } from "../../styles";
import Tooltip from "@mui/material/Tooltip";

const MAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PWD_REGEX = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const NewPassword = () => {
    let navigate = useNavigate();

    const [email, setEMail] = useState("");
    const [validEMail, setValidEMail] = useState(false);
    const [EMailFocus, setEMailFocus] = useState(false);

    const [password, setPassword] = useState("");
    const [validPassword, setValidPassword] = useState(false);
    const [PasswordFocus, setPasswordFocus] = useState(false);

    const [matchpassword, setMatchPassword] = useState("");
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState(false);

    const [tooltipText, setTooltipText] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const userRef = useRef();
    const errRef = useRef();
    const [formData, setFormData] = useState({
      email: "",
      password: "",
    });

    useEffect(() => {
        const result = MAIL_REGEX.test(email);
        //console.log("Email: " + email + " " + result);
        //console.log(email)
        setValidEMail(result);
      }, [email]);
    
    useEffect(() => {
        const result = PWD_REGEX.test(password);
        //console.log("Password: " + password + " " + result);
        //console.log(password)
        setValidPassword(result);
        const match = password === matchpassword;
        setValidMatch(match);
      }, [password, matchpassword]);
  
    //console.log(formData)

    useEffect(() => {
      // Update tooltip text based on validation
  
      let text = "";
      if (
        !validPassword ||
        !validMatch ||
        !validEMail
      )
        text += "\nDeine Eingabe ist falsch bei ";
        if (!validEMail) text += "E-Mail Adresse, ";
      if (!validPassword) text += "Passwort, ";
      if (!validMatch) text += "Passwort-Bestätigung ";
      // Add similar conditions for other fields
      setTooltipText(text);
      //console.log(tooltipText);
    }, [
      validEMail,
      validPassword,
      validMatch,
    ]); // Add dependencies for other fields as needed
  
    const handleSubmit = async (e) => {
    
        e.preventDefault();
        
        const v2 = PWD_REGEX.test(password);
        const v10 = MAIL_REGEX.test(email);
        
        if (!v2 || !v10) {
          setErrMsg("Ungültige Eingabe");
          console.log("das war nichts!");
          console.log(
          PWD_REGEX.test(password),
          MAIL_REGEX.test(email))
          
          return;
        }
        const formData = {
            email: email,
          password: password,
        };
        //console.log(formData);
    
        setSuccess(true);
    
    
        try {
            const { data, error } = await supabase.auth.updateUser({
                email: formData.email,
                password: formData.password,
              });
              navigate('/')
              if (error) throw error;
            } catch (error) {
              alert(error.message);
            }
      };
  
    return (
      <>
        <Text
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </Text>
  
        <Box sx={{margin: 5}}>
          <form className="formcontainer" onSubmit={handleSubmit}>
          <Text style={styles.titleText}>Neues Passwort{'\n'}</Text>
            <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <TextField
                label="E-Mail Adresse"
                fullWidth="true"
                type="text"
                name="email"
                id="email"
                required
                color={validEMail ? "success" : "error"}
                //onInput={handleChange}
                onChange={(e) => {
                  setEMail(e.target.value);
                }}
                inputRef={userRef}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onFocus={() => setEMailFocus(true)}
                onBlur={() => setEMailFocus(false)}
                helperText={
                  !EMailFocus
                    ? ""
                    : validEMail
                    ? ""
                    : "Muss mindestens 6 Zeichen besitzen. Muss ein @ und einen . (Punkt) enthalten."
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Passwort
              </InputLabel>
              <OutlinedInput
                label="Passwort"
                id="password"
                name="password"
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                fullWidth="true"
                color={validPassword ? "success" : "error"}
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                inputRef={userRef}
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => setPasswordFocus(false)}
                aria-describedby="pw-helper-text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="pw-helper-text">
                {!PasswordFocus
                  ? ""
                  : validPassword
                  ? ""
                  : "8 bis 24 Zeichen. Muss eine Groß- und Kleinbuchstaben, eine Nummer und ein Sonderzeichen enthalten. Erlaubte Sonderzeichen sind: !, @, #, $, %"}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Passwort bestätigen
              </InputLabel>
              <OutlinedInput
                label="Passwort bestätigen"
                id="matchpassword"
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                fullWidth="true"
                name="matchpassword"
                color={validMatch ? "success" : "error"}
                required
                onChange={(e) => {
                  setMatchPassword(e.target.value);
                }}
                inputRef={userRef}
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
                aria-describedby="pwm-helper-text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="pwm-helper-text">
                {!matchFocus
                  ? ""
                  : validMatch
                  ? ""
                  : "Die Eingabe stimmt nicht mit dem Passwort überein"}
              </FormHelperText>
            </FormControl>
            <Tooltip title={tooltipText} arrow>
            <Button type="submit" variant="contained" color="success" disabled={validMatch && validPassword && validEMail ? false : true}>
              Passwort Festlegen
            </Button>
            <Text style={styles.smallTextRed}>{tooltipText}</Text></Tooltip>
          </form>
        </Box>
      </>
    );
  };

export default NewPassword
