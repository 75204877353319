import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import supabase from "./config/supabaseClient";
import "./index.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

//Auth

//components
import ChangeRequestDialog from "./pages/Profile/components/ChangeProfileRequestDialog.js";
import NavBar from "./components/NavBar.js";
import { UserProvider } from "./contexts/UserProvider.js";

//Footer
import Footer from "./pages/Footer/Footer.js";
import Impressum from "./pages/Footer/Impressum.js";
import Datenschutz from "./pages/Footer/Datenschutz.js";
import CookiesRichtlinien from "./pages/Footer/Cookies-Richtlinien.js";

// pages
import Startseite from "./pages/Startseite.js";
import Equipment from "./pages/Equipment.js";
import Anbauvereinigung from "./pages/Anbauvereinigung.js";
import Beitragsordnung from "./pages/Beitraege.js";
import Vereinssatzung from "./pages/Vereinsdokumente.js";
import Vereinskodex from "./pages/Vereinskodex.js";
import MitgliedWerden from "./pages/MitgliedWerden.js";
import Galerie from "./pages/Galerie.js";
import Sitemap from "./pages/Sitemap.js";
import Aufklaerung from "./pages/Aufklaerung.js";

// profile
import Profile from "./pages/Profile/Profile.js";
import NewPassword from "./pages/Profile/NewPassword.js";
import ResetPassword from "./pages/Profile/ResetPassword.js";
import SignUp from "./pages/Profile/SignUp.js";
import Login from "./pages/Profile/Login.jsx";

// production
import Statistics from "./pages/Statistic/StatisticPage.js";

// management
import MemberManagement from "./pages/Management/MemberManagement.js";

// plant data
import PlantsData from "./pages/PlantData/PlantsData.js";
import UserContext from "./contexts/UserProvider.js";

function App() {
  const [token, setToken] = useState(false);
  const [role, setRole] = useState(false);

  const [UserData, setUserData] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [news, setNews] = useState(null);

  //console.log(token)
  const [tokenAvailable, setTokenAvailable] = useState(false);
  //const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      const parsedToken = JSON.parse(storedToken);
      setToken(parsedToken);
      setTokenAvailable(true); // Token ist jetzt verfügbar
    }
  }, []);

  useEffect(() => {
    // Überprüfe, ob der Token vorhanden und gleich dem aktuellen Token ist
    if (tokenAvailable && token) {
      const storedToken = localStorage.getItem("token");
      const restoredToken = storedToken ? JSON.parse(storedToken) : null;

      if (restoredToken && restoredToken === token) {
        // Verwende den wiederhergestellten Token
        // Hier kannst du möglicherweise auch zusätzliche Überprüfungen durchführen, je nach Bedarf
        // Setze den wiederhergestellten Token als aktuellen Token
        setToken(restoredToken);
      } else {
        // Token nicht vorhanden oder nicht gleich dem aktuellen Token
        // Führe die übliche Logik für den Fall durch, dass der Token nicht verfügbar ist
      }
    }
  }, [token, tokenAvailable]);

  useEffect(() => {
    if (tokenAvailable && token && token.user && token.user.id) {
      const fetchUserDatas = async () => {
        try {
          if (!token || !token.user || !token.user.id) {
            console.error("Token or user data is not available.");
            return;
          }

          const { data, error } = await supabase
            .from("User")
            .select("*")
            .eq("id", token.user.id);

          //console.log("API Response:", data);

          if (error) {
            setFetchError("Could not fetch the user data");
            console.error(error);
            return;
          }

          if (data) {
            setUserData(data);
            //console.log("UserData after setUserData:", UserData);
          }
        } catch (error) {
          console.error("Error fetching User Data:", error);
          setFetchError("Could not fetch the User Data");
        }
      };

      fetchUserDatas();
    }
  }, [token, tokenAvailable]);

  const isUserAllowed = () => {
    // Wenn kein UserData vorhanden ist oder die Rolle "Bann" ist, ist der Benutzer nicht erlaubt
    return !UserData || (UserData[0] && UserData[0].role === "Bann")
      ? false
      : true;
  };

  //console.log("App:", UserData)

  return (
    <>
      <HelmetProvider>
        <div className="wrapper">
          <NavBar UserData={UserData}></NavBar>
          <div className="wrapper" style={{ marginTop: "64px" }}>
            <header>
              <Helmet>
                <title>
                  Anbauvereinigung Hemp Heaven Hannover - feinste Blüten
                </title>
                <meta
                  name="description"
                  content="Deine Cannabis Club in Hannover für legales qualitätsgesichertes Cannabis und den Austausch zu Anbau, Wirkung und gefahren. Endlich sauberes Weed!"
                />
                <meta
                  name="keywords"
                  content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
                />
              </Helmet>

              <Routes>
                <Route path="/" element={<Startseite token={token} />} />
                {/* <Route path="/2" element={<Home2 token={token} />} /> */}
                <Route path="/signup" element={<SignUp />} />
                <Route
                  path="/login"
                  element={
                    <Login setToken={setToken} onUserDataFetched={setRole} />
                  }
                />
                <Route path="/Vereinsprinzip" element={<Anbauvereinigung />} />
                <Route path="/Sitemap" element={<Sitemap />} />
                <Route path="/Beitragsordnung" element={<Beitragsordnung />} />
                <Route path="/Vereinskodex" element={<Vereinskodex />} />
                <Route path="/Vereinssatzung" element={<Vereinssatzung />} />
                <Route path="/Gesundheit" element={<Aufklaerung />} />
                <Route path="/Mitgliedwerden" element={<MitgliedWerden />} />
                <Route path="/Galerie" element={<Galerie />} />
                <Route path="/Sorten-Tabelle" element={<PlantsData />} />
                <Route path="/Ausruestung" element={<Equipment />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/datenschutz" element={<Datenschutz />} />
                <Route path="/cookies" element={<CookiesRichtlinien />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/newpassword" element={<NewPassword />} />
                <Route
                  path="/profile"
                  element={
                    <Profile
                      setToken={setToken}
                      token={token}
                      UserData={UserData}
                      setUserData={setUserData}
                    />
                  }
                />
                {isUserAllowed() && (
                  <>
                    <Route
                      path="/ChangeRequestDialog"
                      element={
                        <ChangeRequestDialog
                          setToken={setToken}
                          token={token}
                        />
                      }
                    />
                    <Route
                      path="/membership-application"
                      element={<membership-application token={token} />}
                    />
                    <Route
                      path="/member-table"
                      element={
                        <MemberManagement
                          setToken={setToken}
                          token={token}
                          UserData={UserData}
                          setUserData={setUserData}
                        />
                      }
                    />

                    <Route
                      path="/statistics"
                      element={<Statistics token={token} />}
                    />
                  </>
                )}

                {/* {token?:""} wenn es mit diesem ausdruck umklammert ist kann niemand auf den inhalt zugreifen es sei denn die person in eingeloggt.
        token={token} dieser ausdruckt sorgt dafür, dass der token in dieser seite benutzt werden kann. */}
              </Routes>
            </header>
            <footer className="footer-content">
              {/* Hier wird das Impressum am unteren Ende der Seite gerendert */}
              <Footer />
            </footer>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
}

export default App;
