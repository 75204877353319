import React from "react";
import { styles } from "../styles";
import "../index.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Logo from "../components/Logo.js";
import Roadmap from "../components/Roadmap.js";
import PriceModelCards from "../components/PriceModelCards.js";

const Startseite = () => {
  const isScreenSmall = useMediaQuery("(max-width:750px) and (min-width: 0px)");
  const navigate = useNavigate();

  const [value, setValue] = React.useState("1");
  const minWindoSize = 500;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function NavigateToSignIn(e) {
    e.preventDefault();
    navigate("/signup");
  }

  function NavigateToAbout(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate("/Vereinsprinzip");
  }

  function NavigateToProfile(e) {
    e.preventDefault();
    navigate("/profile");
  }

  function NavigateToEquipment(e) {
    e.preventDefault();
    navigate("/Ausruestung");
    window.scrollTo({ top: 0 });
  }
  function NavigateToContributions(e) {
    e.preventDefault();
    navigate("/Beitragsordnung");
    window.scrollTo({ top: 0 });
  }

  return (
    <HelmetProvider>
      <div className="page-home">
        <Helmet>
          <title>
            Anbauvereinigung Hemp Heaven Hannover - feinste Blüten und mehr
          </title>
          <meta
            name="description"
            content="Informiere dich über die Anbauvereinigung Hemp Heaven Hannover und entscheide ob du interesse an unserem organischen Cannabis hast, profitiere von hervorragendem Know-How, tausche dich mit Mitgliedern über die vielfalt von Cannabis aus oder lehn dich einfach zurück und lass uns für dich die feinsten Blüten heranwachsen."
          />
          <meta
            name="keywords"
            content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
          />
        </Helmet>

        <Box
          component={Paper}
          sx={{
            maxWidth: 800,
            minWidth: 300,
            marginTop: 5,
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Logo />

          <Roadmap />
          {/* <Text style={styles.titleText}>Hanffarm AV Hannover {"\n"}</Text> */}
          {/*                 <div style={{ textAlign: "center" }}>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        Momentan nehmen wir keine neuen Mitglieder auf. Ihr könnt
                        euch dennoch auf dieser Website registrieren und werdet
                        dadurch auf eine Warteliste aufgenommen.
                      </span>
                    </div>
                    {"\n"}
                    {"\n"} */}
          <h1
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Anbauvereinigung Hemp Heaven Hannover
          </h1>

          <p style={{ ...styles.normalText }}>
            Entdecke eine Oase für Cannabis-Enthusiast:innen in Linden,
            Hannover. Wir sind eine Anbauvereinigung, die sich der Kultivierung
            von hochwertigem Cannabis verschrieben hat. Bei uns steht Qualität
            an erster Stelle: feinste Blüten, sorgfältig von Hand angebaut,
            gepflegt und geerntet.
          </p>
          <p style={{ ...styles.normalText }}>
            Unser Alleinstellungsmerkmal liegt nicht nur in der Qualität unseres
            Produkts, sondern auch in unserem einzigartigen Ansatz. Bei uns
            kannst du deinen monatlichen Bedarf ganz unkompliziert anpassen,
            Gramm für Gramm. So hast du immer genau die Menge, die du benötigst,
            ohne Überschuss oder Engpässe. Außerdem sind wir eine familiäre
            Anbauvereinigung, die mit Blut, Schweiß, Fleiß und Tränen von
            Hannoveraner:innen für Hannoveraner:innen da ist!
          </p>
          <p style={{ ...styles.normalText }}>
            Tauche ein in die Welt des hochqualitativen Cannabis und erlebe Hemp
            Heaven Hannover – wo Leidenschaft, Qualität und Gemeinschaft
            zusammenkommen.
          </p>
          <p style={{ ...styles.boldText }}>
            Bereit, das Paradies zu betreten? Registriere dich jetzt – kostenlos
            und unverbindlich!
          </p>
          <Button
            className="signin"
            variant="contained"
            color="success"
            align-items="flex-start"
            onClick={NavigateToSignIn}
            sx={{
              marginRight: "auto",
              "@media screen and (max-width: 380px)": {
                // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                fontSize: 10,
                // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
              },
            }}
          >
            Registriere dich für den Himmel
          </Button>
{/*           <p style={styles.boldTextRed}>
            Wer sich bis zum 23.07.2024 als Mitglied angemeldet hat, erhält ab
            der ersten Abgabe ein Jahr 1 € Rabatt auf jedes Gramm Cannabis!
          </p> */}

          {/* <h2
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Meilensteine
          </h2>
          <Milestones />*/}
          <h2
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Wie funktioniert der Anbau beim Hemp Heaven Hannover?
          </h2>
          <p style={styles.normalText}>
            Jedes Mitglied bei uns im Hemp Heaven Hannover teilt sich ein
            Gewächszelt mit zwei weiteren Mitgliedern. In diesem Zelt werden
            drei Pflanzen für jedes Mitglied gepflegt. Jedes Zelt ist voll
            funktionsfähig und verfügt über sein eigenes Klima, das
            kontinuierlich überwacht und gesteuert wird.
          </p>
          <p style={styles.normalText}>
            Der Anbau beginnt mit Torfquelltöpfen und einem beheizten
            Gewächshaus, um die Samen zum Keimen zu bringen. Nach dieser Phase
            werden die Keimlinge unter Anzuchtlampen platziert, um weiter an
            Kraft zu gewinnen, bevor sie in die größeren Töpfe im Hauptzelt
            verpflanzt werden.
          </p>
          <p style={styles.normalText}>
            Sobald die Keimlinge groß genug sind, werden sie in eine Mischung
            aus leicht gedüngtem Erdboden, Kokosfasern und organischer
            Düngemischung gepflanzt. Hier werden die Pflanzen liebevoll gepflegt
            und betreut, bis sie erntereif sind.
          </p>
          <p style={styles.normalText}>
            Die Ernte beginnt mit dem Entfernen überschüssiger Blätter, bis nur
            noch die Blüten übrig sind. Die gesamte Pflanze wird dann am Stamm
            abgetrennt und zum Trocknen aufgehängt. Anschließend wird bereits
            die nächste Pflanze eingetopft, und der Zyklus beginnt von Neuem.
          </p>
          <p style={styles.normalText}>
            Sobald die getrockneten Blüten bereit sind, werden sie von den Ästen
            befreit und erhalten ihren Feinschnitt. Der Feinschnitt wird
            gesammelt und zu Haschisch weiterverarbeitet, während die Blüten in
            Einmachgläsern veredelt werden, um ihren vollen Geschmack zu
            entfalten – jede Pflanze hat ihr eigenes Einmachglas. In
            Zusammenarbeit mit einem Analyselabor werden die Blüten auf ihre
            Inhaltsstoffe untersucht.
          </p>
          <p style={styles.normalText}>
            Jeder Schritt dieses Prozesses wird, dank unserer eigens
            entwickelten Produktionssoftware sorgfältig überwacht und
            dokumentiert. So können wir sicherstellen, dass jedes Mitglied bei
            Hemp Heaven Hannover nur das Beste erhält – hochqualitatives
            Cannabis, mit Liebe und Fachkenntnis angebaut.
          </p>
          <p style={styles.normalText}>
            Wir planen, von jeder unserer Sorten auch Rosin herzustellen. Dazu
            ist es zunächst notwendig, die erforderliche Ausrüstung zu
            beschaffen und die Prozesssicherheit zu gewährleisten, um auch in
            diesem Bereich eine hervorragende Qualität sicherstellen zu können.
          </p>
          <Button
            className="signin"
            variant="contained"
            color="success"
            align-items="flex-start"
            onClick={NavigateToEquipment}
            sx={{
              marginRight: "auto",
              "@media screen and (max-width: 380px)": {
                // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                fontSize: 10,
                // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
              },
            }}
          >
            Zur Ausrüstung
          </Button>
          <Button
            className="signin"
            variant="contained"
            color="success"
            align-items="flex-start"
            onClick={NavigateToAbout}
            sx={{
              marginRight: "auto",
              "@media screen and (max-width: 380px)": {
                // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                fontSize: 10,
                // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
              },
            }}
          >
            Zum Vereinsprinzip
          </Button>

          <h2
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Wirke bei der Anbauvereinigung mit
          </h2>
          <p style={styles.normalText}>
            Bei Hemp Heaven Hannover hast du nicht nur die Möglichkeit,
            hochqualitatives Cannabis zu genießen – du kannst auch aktiv an der
            Gestaltung unseres{" "}
            <a title="Sorten-Tabelle" href="/plantsdata">
              Sortiments
            </a>{" "}
            teilnehmen!
          </p>

          {"\n"}
          {"\n"}
          <p style={styles.normalText}>
            In regelmäßigen Abständen veranstalten wir Umfragen, bei denen jedes
            Mitglied für seine Lieblingssorte stimmen kann. Deine Stimme zählt!
            Zum Ende der Umfrage werden die Indica-, Sativa- und Hybrid-Sorten
            mit der höchsten Nachfrage für den nächsten Anbauzyklus ausgewählt.
          </p>
          {"\n"}
          {"\n"}
          <p style={styles.normalText}>
            Aber das ist noch nicht alles. Nachdem wir als Verein einige Sorten
            ausprobiert haben, hast du die Möglichkeit, deine persönlichen
            Favoriten auszuwählen. Du kannst deine eigene Sorte bestimmen – die
            dann extra für dich angebaut wird, ganz nach deinen Vorlieben und
            Bedürfnissen.
          </p>
          <p style={styles.normalText}>
            Außerdem kannst du auch deine Bewertung zu jeder Sorte, die du
            probiert hast, abgeben, dich an der Gestaltung der Öffnungszeiten
            beteiligen, und es ist geplant, ein internes Forum für
            Erfahrungsberichte einzurichten, in dem jedes Mitglied anonym seine
            Erfahrungen zu der jeweiligen Sorte schreiben kann.
          </p>
          <p style={styles.boldText}>
            Bist du bereit, deine Sorte zu wählen? Mach mit bei unserer nächsten
            Umfrage und probiere dich durch die verschiedensten Sorten!
          </p>
          <Button
            className="signin"
            variant="contained"
            color="success"
            align-items="flex-start"
            onClick={NavigateToProfile}
            sx={{
              marginRight: "auto",
              "@media screen and (max-width: 380px)": {
                // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                fontSize: 10,
                // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
              },
            }}
          >
            An Umfragen Teilnehmen!
          </Button>
          <h2
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Du hast keine Verpflichtungen
          </h2>
          <p style={styles.normalText}>
            Der Hemp Heaven Hannover ist eine unabhängige Anbauvereinigung, die
            von Herzen kommt! Hier brauchst du weder Mitglied in einem anderen
            Verein zu werden, noch sind wir Teil einer Franchise-Kette oder
            irgendwelchen Investoren und Investorinnen verpflichtet. Unser
            Verein basiert ausschließlich auf dem Herzblut und der Arbeit
            unserer Gründungsmitglieder, um allen zukünftigen Mitgliedern ein
            faires und tragbares Konzept zu bieten.
          </p>
          <p style={styles.boldText}>
            Du brauchst dir auch keine Sorgen machen, dass du verpflichtet
            wirst, Gartenarbeit zu betreiben. Das übernehmen wir gerne für dich.
            Unser Ziel ist es, dir das beste Cannabis-Erlebnis zu bieten, ohne
            den Stress der Pflege und des Anbaus.
          </p>
          <p style={styles.normalText}>
            Im Hemp Heaven Hannover kannst du nicht nur hochqualitatives
            Cannabis genießen, sondern auch mehr über den Anbau erfahren. In
            unserem Vereinsheim wird ein Gewächszelt aufgestellt, in dem unsere
            Mitglieder die Pflege der Cannabis-Pflanzen hautnah miterleben
            können. Lerne von unseren Experten und entdecke die Geheimnisse
            hinter dem perfekten Anbau.
          </p>
          <p style={styles.boldText}>
            Sei Teil unserer Gemeinschaft und gestalte mit uns die Zukunft der
            Anbauvereinigung aus Hannover für Hannover!
          </p>

          <h2
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Ein faires und tragbares Preismodell
          </h2>
          <p style={styles.normalText}>
            Um den Verein für euch aufbauen zu können und dabei unabhängig zu
            bleiben, benötigen wir eure Unterstützung. Dafür haben wir uns ein
            Konzept überlegt, bei dem jedes Mitglied einen kleinen Beitrag dazu
            leistet. Damit wir die Ausrüstung für die Anbauvereinigung
            beschaffen können, benötigen wir von jedem Mitglied, für das wir auf
            unserer Anbaufläche die Pflanzen pflegen, eine
            Erstausstattungsgebühr.
          </p>
          <p style={styles.boldText}>
            Wir wissen, dass 500 € viel Geld ist, und deshalb erhältst du,
            nachdem die Erstausstattungsgebühr bei uns auf dem Konto eingegangen
            ist, eine Bescheinigung, dass dir das Gewächszelt zu einem Drittel
            gehört. Diese Bescheinigung wird der Verein dir für ca. 300 €
            abkaufen, um das Gewächszelt in das Vereinsinventar aufnehmen zu
            können.
          </p>
          {/*           <p style={styles.normalTextRed}>
            Derzeit bemühen wir uns um einen Bankkredit, um die
            Erstausstattungsgebühr möglichst stark zu reduzieren. Mit Hilfe des
            Kredits möchten wir die Ausstattung unserer Produktionsanlage
            finanzieren. Dadurch würdet ihr eine wesentlich geringere
            Erstausstattungsgebühr zahlen müssen. Eine solche Gebühr ist dennoch
            erforderlich, um grundlegende Verbrauchsmaterialien wie Samen, Erde
            und Hygieneartikel beschaffen zu können.
          </p> */}
          <p style={styles.normalText}>
            Damit die laufenden Kosten gezahlt werden können, benötigen wir von
            jedem Mitglied einen Basis-Mitgliedsbeitrag von 25 € pro Monat.
            Damit werden Mieten (für das Vereinsheim und die Anbaufläche),
            Versicherungen, Internetverträge usw. gezahlt. Der Beitrag wird sich
            mit steigender Mitgliederzahl verringern. Erzählt es also auch euren
            Freunden.
          </p>
          <PriceModelCards />
          <Button
            className="signin"
            variant="contained"
            color="success"
            align-items="flex-start"
            onClick={NavigateToContributions}
            sx={{
              marginRight: "auto",
              "@media screen and (max-width: 380px)": {
                // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                fontSize: 10,
                // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
              },
            }}
          >
            Zur Beitragsordnung
          </Button>
{/*           <p style={styles.boldTextRed}>
            Wer sich bis zum 23.07.2024 als Mitglied angemeldet hat, erhält ab
            der ersten Abgabe ein Jahr 1 € Rabatt auf jedes Gramm Cannabis!
          </p> */}
          <h3
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Wie bekomme ich mein Cannabis?
          </h3>
          <p style={styles.normalText}>
            Wenn du auf dieser Website registriert bist und als Mitglied
            aufgenommen wurdest, gibst du deinen Monatsbedarf ganz einfach und
            unkompliziert auf deinem Profil für den Folgemonat an. Sofern du
            diesen Wert nicht anpasst, steht dir für die folgenden Monate immer
            diese Menge zur Verfügung. Du zahlst dann dafür einen reduzierten
            Bedarfs-Mitgliedsbeitrag von 7 € pro Gramm Cannabis, das gemeinsam
            mit dem Basis-Mitgliedsbeitrag von deinem Konto abgebucht wird.
          </p>
          <p style={styles.normalText}>
            Du hast dich verschätzt und benötigst doch etwas mehr? Kein Problem!
            Du kannst im Vereinsheim Sonderbeiträge zahlen, um zusätzliches
            Cannabis zu erhalten. Dafür zahlst du 9 € pro Gramm, welches dir im
            Folgemonat von deinem Konto abgebucht wird.
          </p>
          <h2
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Der Vorstand
          </h2>

          <p style={styles.normalText}>
            Der Vorstand besteht derzeit aus einer Person: Hannes Lokatis. Ich
            bin Zerspanungsmechaniker für Frästechnik, Maschinenbauingenieur für
            Konstruktion und Entwicklung und verfüge über berufliche Erfahrungen
            als Prüfingenieur, Software-Testmanager und Prozessingenieur. Ich
            habe das gesamte Konzept der Anbauvereinigung mit Hilfe meiner
            Freunde und Familie konzipiert, einen Businessplan erstellt,
            Kalkulationen durchgeführt, mich um die Beschaffung der Immobilien
            gekümmert, die Mitglieder- und Produktions-Websites erstellt und bin
            als Fördermitglied für die Cannabis-Anbauvereinigungen Deutschlands
            (CAD) tätig. Als Fördermitglied der CAD habe ich mich an der
            Erstellung eines Anbauvereinigungs-Kalkulationstools sowie eines
            allumfassenden Gesundheits- und Jugendschutzkonzepts beteiligt. Als
            langjähriger Cannabis-Konsument freue ich mich, endlich die Vielfalt
            der Cannabis-Pflanze in Geschmack und Effekt probieren zu können.
            Ich betrachte Cannabis als ein Genussmittel, ähnlich wie einen guten
            Whiskey oder eine Zigarre, das nur zu bestimmten Anlässen genossen
            werden sollte. Als Schlüsselfigur des Vereins schätze ich jedes
            Mitglied und das mir entgegengebrachte Vertrauen und werde alles
            dafür tun, um bestmögliche Qualität und das Wohlbefinden der
            Mitglieder zu gewährleisten.
          </p>
          <img
            title="Vorstand- Hannes Lokatis"
            src={require("../images/Vorstand-01.webp")}
            alt="Bild des Vorstandvorsitzenden Hannes Lokatis"
            style={{
              width: 350,
              resizeMode: "contain",
            }}
          />
        </Box>
      </div>
    </HelmetProvider>
  );
};

export default Startseite;
