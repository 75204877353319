import { styles } from "../../styles";
import { View, Text } from "react-native";

//Components
import GraphVarietySurvey from "./components/GraphVarietySurvey";
import ResultRatingSurvey from "./components/ResultRatingSurvey";
import CurrentVariety from "./components/CurrentVariety";

//MUI
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Stack } from "@mui/system";

const Statistics = (props) => {
  const { token } = props;
  const isScreenSmall = useMediaQuery("(max-width:950px)");


  return (
    <>
      {token ? (
        <div>
          {isScreenSmall ? (
            <Stack spacing={2} marginTop={2} marginLeft={1} marginRight={1}>
              <CurrentVariety></CurrentVariety>
              <ResultRatingSurvey></ResultRatingSurvey>
              <GraphVarietySurvey></GraphVarietySurvey>
            </Stack>
          ) : (
            <div className="page home">
              <Grid container={true} spacing={1}>
              <Grid item={true} xs={12}>
                  <CurrentVariety></CurrentVariety>
                </Grid>
                <Grid item={true} xs={8}>
                  <GraphVarietySurvey></GraphVarietySurvey>
                </Grid>
                <Grid item={true} xs={4}>
                  <ResultRatingSurvey></ResultRatingSurvey>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      ) : (
        <section>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Text style={styles.titleText}>
              Wenn du angemeldet bist, werden hier die Umfrageergebnisse
              angezeigt.
            </Text>
          </View>
          {/* <p>
          <Link to="/login">
            <Button variant="contained" className="signin">Anmelden</Button>
          </Link>
        </p> */}
        </section>
      )}
    </>
  );
};

export default Statistics;
