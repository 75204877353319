import { useState, useRef } from "react";
import { Text } from "react-native";
import { styles } from "../../styles";
import "../../App.css";
import supabase from "../../config/supabaseClient";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { TextField, Box } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";

import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

const Login = ({ setToken, onUserDataFetched }) => {
  let navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [EMailFocus, setEMailFocus] = useState(false);
  const [PasswordFocus, setPasswordFocus] = useState(false);
  async function fetchUserData(userEmail) {
    try {
      const { data, error } = await supabase
        .from("User")
        .select("role")
        .eq("email", userEmail)
        .single();

      if (error) {
        console.error("Fehler beim Abrufen der Benutzerdaten:", error.message);
        return;
      }
      // Setze die Benutzerrolle im State
      onUserDataFetched(data.role);

      // Hier kannst du weiteren Code hinzufügen, der von role abhängt
      //console.log("Aktualisierter Role-Status:", data.role);
    } catch (error) {
      console.error("Fehler beim Abrufen der Benutzerdaten:", error.message);
    }
  }

  //console.log(formData)

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.email,
        password: formData.password,
      });

      if (error) throw error;
      localStorage.setItem("token", JSON.stringify(data));
      setToken(data);

      navigate("/profile");

      window.location.reload(false);
      // Rufe die Benutzerdaten ab und setze die Benutzerrolle
      fetchUserData(data.user.email);
    } catch (error) {
      alert(error);
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Anmeldung der Anbauvereinigung Hemp Heaven Hannover</title>
          <meta
            name="description"
            content="Wenn du die Registrierung der Anbauvereinigung Hemp Heaven Hannover abgeschlossen hast kannst du dich hier mit deinen Anmeldedaten anmelden um noch mehr Inhalte zu sehen."
          />
          <meta
            name="keywords"
            content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
          />
        </Helmet>
        <Text
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </Text>

        <Box sx={{ margin: 5 }}>
          <form className="formcontainer" onSubmit={handleSubmit}>
            <Text style={styles.titleText}>Login</Text>
            {/* <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <TextField
                placeholder="E-Mail Addresse"
                name="email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <TextField
                placeholder="Passwort"
                name="password"
                type="password"
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
            </FormControl> */}

            <FormControl fullWidth sx={{ m: 1 / 2 , mt: 2}}>
              <TextField
                label="E-Mail Adresse"
                type="text"
                name="email"
                id="email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                autoComplete="username"
                inputRef={userRef}
                inputProps={{
                  startadornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onFocus={() => setEMailFocus(true)}
                onBlur={() => setEMailFocus(false)}
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Passwort
              </InputLabel>
              <OutlinedInput
                label="Passwort"
                id="password"
                name="password"
                autoComplete="current-password"
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                type={showPassword ? "text" : "password"}
                inputRef={userRef}
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => setPasswordFocus(false)}
                aria-describedby="pw-helper-text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Text>
              Du hast noch keinen Account?{" "}
              <Link to="/signup">Registrieren</Link>
            </Text>
            <br></br>
            <Text>
              Du hast dein Passwort vergessen?{" "}
              <Link to="/resetpassword">Passwort zurücksetzen</Link>
            </Text>
            <br></br>
            <br></br>
            <Button type="submit" variant="contained" color="success">
              Anmelden
            </Button>
          </form>
        </Box>
      </HelmetProvider>
    </>
  );
};

export default Login;
