import React from "react";
import { styles } from "../styles";
import "../index.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Vereinssatzung2 from "../pdf/Vereinssatzung.pdf";
import Beitragsordnung from "../pdf/Beitragsordnung.pdf";
import Linkleiste from "../components/LinkLeiste.js";
import Logo from "../components/Logo.js";

const Vereinssatzung = () => {
  const isScreenSmall = useMediaQuery("(max-width:750px) and (min-width: 0px)");

  const [value, setValue] = React.useState("1");
  const minWindoSize = 500;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <HelmetProvider>
      <div className="page-home">
        <Helmet>
          <title>
            Die offiziellen Dokumente der Anbauvereinigung Hemp Heaven Hannover
          </title>
          <meta
            name="description"
            content="Informiere dich darüber was genau in der Satzung und Beitragsordnung der Anbauvereinigung Hemp Heaven Hannover geschrieben steht und wie genau das Vereinsleben geregelt ist."
          />
          <meta
            name="keywords"
            content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
          />
        </Helmet>
        <Box
          component={Paper}
          sx={{
            maxWidth: 800,
            minWidth: 300,
            marginTop: 5,
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Logo />

          <h1
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Vereinssatzung Hemp Heaven Hannover
          </h1>

          <h2 style={{ ...styles.subTitelText }}>
            § 1 Name und Sitz des Vereins, Geschäftsjahr
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Der Verein führt den Namen “Hemp Heaven Hannover”. Er soll in
            das Vereinsregister eingetragen werden und führt danach den Zusatz
            „e.V.“.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Der Verein hat seinen Sitz in Hannover. Der Verein wurde am
            13.04.2024 gegründet.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Der Verein ist parteipolitisch, ethisch und konfessionell
            neutral.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (4) Das Geschäftsjahr ist das Kalenderjahr.
          </p>

          <h2 style={{ ...styles.subTitelText }}>§ 2 Zweck des Vereins</h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Der Zweck des Vereins ist der gemeinschaftliche Eigenanbau und
            die Weitergabe des im gemeinschaftlichen Eigenanbau angebauten
            Cannabis durch und an seine Mitglieder zum Eigenkonsum, die
            Information von Mitgliedern über cannabisspezifische Suchtprävention
            und -beratung sowie die Weitergabe von gemeinschaftlichen Eigenanbau
            gewonnenen Vermehrungsmaterial für den privaten Eigenanbau an ihre
            Mitglieder, an sonstige Personen, die das 18. Lebensjahr vollendet
            haben, oder an andere Anbauvereinigungen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Der Verein ist selbstlos tätig; er verfolgt nicht in erster
            Linie eigenwirtschaftliche Zwecke.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Mittel des Vereins dürfen nur für die satzungsmäßigen Zwecke
            verwendet werden.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (4) Es darf keine Person durch Ausgaben, die dem Zweck des Vereins
            fremd sind, oder durch unverhältnismäßig hohe Vergütungen begünstigt
            werden.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (5) Die Mitglieder des Vorstands können für ihren Arbeits- oder
            Zeitaufwand Vergütungen erhalten. Der Umfang der Vergütungen darf
            nicht unangemessen hoch sein.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 3 Erwerb der Mitgliedschaft
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Mitglied des Vereins kann jede natürliche und juristische Person
            werden, sofern diese Person das 18. Lebensjahr vollendet und einen
            Wohnsitz oder gewöhnlichen Aufenthalt in Deutschland hat.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Die Aufnahme in den Verein ist schriftlich und persönlich mit
            Vorlage eines gültigen Lichtbildausweises beim Vorstand zu
            beantragen. Der Vorstand entscheidet über den Aufnahmeantrag nach
            freiem Ermessen. Eine Ablehnung des Antrags muss er gegenüber
            der/dem Antragsteller:in nicht begründen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Das zukünftige Mitglied muss dem Verein gegenüber schriftlich
            versichern, dass es kein Mitglied in einer anderen Anbauvereinigung
            ist.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (4) Auf Vorschlag des Vorstands kann die Mitgliederversammlung
            Mitglieder oder sonstige Personen, die sich um den Verein besonders
            verdient gemacht haben, zu Ehrenmitglieder:innen ernennen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (5) Die Mitgliederzahl ist auf 500 Mitglieder begrenzt.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 4 Beendigung der Mitgliedschaft
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>(1) Die Mitgliedschaft endet</p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) mit dem Tod des
            Mitglieds,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) durch
            freiwilligen Austritt,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) durch Streichung
            von der Mitgliederliste,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) durch Änderung
            des Wohnsitz oder des gewöhnlichen Aufenthalts auf einen Ort
            außerhalb von Deutschland,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e) durch Ausschluss
            aus dem Verein,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f) bei juristischen
            Personen durch deren Auflösung.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Der freiwillige Austritt erfolgt durch schriftliche Erklärung
            gegenüber einem Mitglied des Vorstands. Er ist nur zum Schluss eines
            Kalenderjahres unter Einhaltung einer Kündigungsfrist von drei
            Monaten zulässig.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Mitglieder können mit sofortiger Wirkung aus dem Verein
            austreten, wenn sie eine Schwangerschaft oder eine Suchtbehandlung
            nachweisen können.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (4) Ein Mitglied kann durch Beschluss des Vorstands von der
            Mitgliederliste gestrichen werden, wenn es trotz zweimaliger Mahnung
            mit der Zahlung des Beitrags im Rückstand ist oder wenn es
            schuldhaft das Ansehen oder die Interessen des Vereins geschädigt
            hat. Das Mitglied ist über den Beschluss elektronisch zu informieren
            und hat bis zwei Wochen vor der Streichung die Möglichkeit, sich zum
            Sachverhalt zu äußern. Geschieht dies nicht, gilt der Beschluss als
            angenommen und die Streichung wird zum gesetzten Termin
            durchgeführt. Bei einer fristgerechten Berufung entscheidet die
            Mitgliederversammlung über den endgültigen Beschluss mit einfacher
            Mehrheit. Bis zur Entscheidung der Mitgliederversammlung ist das
            betroffene Mitglied von den Angeboten des Vereins ausgeschlossen.
            Die Streichung ist dem Mitglied elektronisch mitzuteilen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (5) Ein Ausschluss ist mit sofortiger Wirkung dann zulässig, wenn
            ein Mitglied nachweislich Cannabis oder Cannabis-Erzeugnisse an
            andere Personen weitergegeben hat.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (6) Die Mitgliedschaft muss mindestens drei Monate andauern.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 5 Rechte und Pflichten der Mitglieder
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Jedes Mitglied hat gleiches Stimm- und Wahlrecht in der
            Mitgliederversammlung.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Jedes Mitglied muss sich bei Anmeldung für eines von zwei
            Pflegemodellen entscheiden.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a)
            Vereinspflege-Modell: Der Anbau und die Pflege der Pflanzen auf den
            Anbauflächen des Vereins
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b)
            Privatpflege-Modell: Kein Anbau und keine Pflege der Pflanzen auf
            den Anbauflächen des Vereins
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Jedes Mitglied hat das Recht, gegenüber dem Vorstand und der
            Mitgliederversammlung Anträge zu stellen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (4) Jedes Mitglied hat die Pflicht, die Interessen des Vereins zu
            fördern, insbesondere regelmäßig seine Mitgliedsbeiträge zu leisten
            und, soweit es in seinen Kräften steht, das Vereinsleben durch seine
            Mitarbeit zu unterstützen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (5) Jedes Mitglied hat die Pflicht, die Auflagen der von der
            Bundesregierung geltenden Gesetze zum Gebrauch und zur Weitergabe
            von Cannabisprodukten einzuhalten.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (6) Jedes Mitglied ist verpflichtet, dem Verein Änderungen ihrer
            Postadresse und E-Mail-Adresse umgehend mitzuteilen. Für Folgen, die
            aus einer Versagung dieser Pflicht entstehen, haftet das Mitglied
            und stellt den Verein von jeglicher Haftung frei.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (7) Jedes Mitglied hat das Recht, eine Ehrenamtspauschale laut
            Gesetzgebung zu erhalten, wenn es nachweislich mindestens 100
            Stunden im Jahr geleistet hat. Die Ehrenamtspauschale kann nur dann
            ausgezahlt werden, wenn die Tätigkeit nebenberuflich ausgeübt wird.
            Die Tätigkeit darf nicht mehr als ein Drittel der Zeit einer
            vergleichbaren Vollzeit-Erwerbstätigkeit umfassen. Zu den
            ehrenamtlichen Tätigkeiten zählen: a. Kassentätigkeiten b. Hof- und
            Gebäudereinigung c. Pflanzenpflege d. vom erweiterten Vorstand
            durchgeführte administrative Tätigkeiten e. Beteiligung an
            kommunalen sozialen Projekten
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 6 Aufnahmegebühr und Mitgliedsbeiträge
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Jedes Mitglied hat eine im Voraus fällige Aufnahmegebühr von 20
            € zu zahlen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Jedes Mitglied zahlt einen monatlichen Basis-Mitgliedsbeitrag.
            Die Höhe des Beitrages wird in der Beitragsordnung festgelegt.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Mitglieder, die ihre Pflanzen im befriedeten Besitztum des
            Vereins pflegen wollen, können einen Bedarfs-Mitgliedsbeitrag
            zahlen, um Cannabis zu erhalten. Die Höhe des Beitrages wird in der
            Beitragsordnung festgelegt.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (4) Es können im Vereinsheim Sonderbeiträge entrichtet werden. Die
            Bedingungen und die Höhe der Sonderbeiträge werden in der
            Beitragsordnung festgelegt.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (5) Jedes Mitglied, das sich für eine Pflanzenpflege im Verein
            entscheidet, muss eine Erstausstattungsgebühr entrichten. Die Höhe
            der Erstausstattungsgebühr ist in der Beitragsordnung festgelegt.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (6) Ehrenmitglieder sind von den Basis-Mitgliedsbeiträgen befreit.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (7) Die Beiträge werden am 15. eines jeden Monats per
            SEPA-Lastschrift-Verfahren vom angegebenen Konto des Mitglieds
            abgebucht.
          </p>

          <h2 style={{ ...styles.subTitelText }}>§ 7 Organe des Vereins</h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            Organe des Vereins sind der Vorstand und die Mitgliederversammlung.
          </p>

          <h2 style={{ ...styles.subTitelText }}>§ 8 Vorstand</h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Der Vorstand besteht aus mindestens einer Person und kann aus
            bis zu drei Personen bestehen. Besteht der Vorstand aus mehr als
            einer Person, dann sind seine Funktionen Vorstandsvorsitzender,
            stellvertretender Vorsitzender und Schatzmeister
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Jedes Vorstandsmitglied vertritt den Verein allein.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Den Mitgliedern des Vorstands kann eine angemessene Vergütung
            gezahlt werden. Über die Höhe der Vergütung entscheidet die
            Mitgliederversammlung.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (4) Ein Vorstandsmitglied kann über seine Amtszeit hinaus im Amt
            bleiben, bis ein neues Mitglied zum Vorstand gewählt oder die
            Wiederwahl bestätigt ist.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (5) Ein Vorstandsmitglied kann jederzeit mit einer dreimonatigen
            Vorlauffrist sein Amt kündigen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (6) Scheidet ein Mitglied des Vorstandes während der Amtsperiode
            aus, so wählt der Vorstand ein Ersatzmitglied (aus den Reihen der
            Vereinsmitglieder) für die restliche Amtsdauer des Ausgeschiedenen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (7) Ein Vorstandsmitglied muss Vereinsmitglied sein.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (8) Die Vorstandsmitglieder sind von den Beschränkungen des § 181
            BGB befreit, solange das In-Sich-Geschäft ein privates Darlehen
            betrifft, das sich am Leitzins der Europäischen Zentralbank (EZB)
            oder unterhalb davon orientiert und der Verein der Darlehensnehmer ist.
          </p>

          <h2 style={{ ...styles.subTitelText }}>§ 9 Aufgaben des Vorstands</h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1)Dem Vorstand des Vereins obliegen die Vertretung des Vereins nach
            § 26 BGB und die Führung seiner Geschäfte. Er hat insbesondere
            folgende Aufgaben:
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) die Einberufung
            und Vorbereitung der Mitgliederversammlungen einschließlich der
            Aufstellung der Tagesordnung,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) die Ausführung
            von Beschlüssen der Mitgliederversammlung,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) die Verwaltung
            des Vereinsvermögens und die Anfertigung des Jahresberichts,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) die Aufnahme
            neuer Mitglieder,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e) die Umsetzung von
            Änderungen oder Ergänzungen der Satzung, die von einer zuständigen
            Behörde vorgeschrieben werden. Für Änderungen und Ergänzungen dieser
            Art bedarf es keiner Mitgliederversammlung, die Mitglieder müssen
            lediglich über die Änderungen und Ergänzungen elektronisch
            informiert werden.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f) Abschluss und
            Kündigung von Dienst- und Arbeitsverhältnissen.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 10 Bestellung des Vorstands
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Die Mitglieder des Vorstands werden von der
            Mitgliederversammlung für die Dauer von fünf Jahren einzeln gewählt.
            Mitglieder des Vorstands können nur Mitglieder des Vereins sein; mit
            der Mitgliedschaft im Verein endet auch die Mitgliedschaft im
            Vorstand. Die Wiederwahl oder die vorzeitige Abberufung eines
            Vorstandsmitglieds durch die Mitgliederversammlung ist nur dann
            zulässig, wenn das Vorstandsmitglied nachweislich gegen die
            Interessen des Vereins gehandelt hat.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Scheidet ein Mitglied vorzeitig aus dem Vorstand aus, so sind
            die verbleibenden Mitglieder des Vorstands berechtigt, ein Mitglied
            des Vereins bis zur Wahl der Nachfolgerin oder des Nachfolgers durch
            die Mitgliederversammlung in den Vorstand zu wählen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Es kann durch eine Mitgliederversammlung ein erweiterter
            Vorstand gewählt werden, um den Vorstand in administrativen Aufgaben
            zu entlasten.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 11 Beratung und Beschlussfassung des Vorstands
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Der Vorstand tritt nach Bedarf zusammen. Die Sitzungen werden
            vom Vorsitzenden, bei dessen Verhinderung von seinem Stellvertreter,
            einberufen. Eine Einberufungsfrist von einer Woche soll eingehalten
            werden. Der Vorstand ist beschlussfähig, wenn mindestens die Hälfte
            seiner Mitglieder anwesend ist. Bei der Beschlussfassung entscheidet
            die Mehrheit der abgegebenen gültigen Stimmen. Bei Stimmengleichheit
            entscheidet die Stimme des Vorsitzenden, bei dessen Verhinderung die
            seines Stellvertreters.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Die Beschlüsse des Vorstands sind zu protokollieren. Das
            Protokoll ist vom Protokollführer sowie vom Vorsitzenden, bei dessen
            Verhinderung von seinem Stellvertreter oder einem anderen Mitglied
            des Vorstands zu unterschreiben.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 12 Aufgaben der Mitgliederversammlung
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            Die Mitgliederversammlung ist zuständig für die Entscheidungen in
            folgenden Angelegenheiten:
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) die Änderungen
            der Satzung,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) die Festsetzung
            der Aufnahmegebühr und der Beiträge,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) die Ernennung von
            Ehrenmitgliedern sowie der Ausschluss von Mitgliedern aus dem
            Verein,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) die Entscheidung
            über die Höhe der Vergütung des Vorstands,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e) die Wahl und die
            Abberufung der Mitglieder des Vorstands,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f) die Entgegennahme
            des Jahresberichts und die Entlastung des Vorstands,
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g) die Auflösung des
            Vereins.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 13 Einberufung der Mitgliederversammlung
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Mindestens einmal im Jahr, möglichst im letzten Quartal, ist vom
            Vorstand eine ordentliche Mitgliederversammlung einzuberufen. Die
            Einberufung erfolgt elektronisch unter Einhaltung einer Frist von
            zwei Wochen und unter Angabe der Tagesordnung.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Die Tagesordnung setzt der Vorstand fest. Jedes Vereinsmitglied
            kann bis spätestens eine Woche vor der Mitgliederversammlung beim
            Vorstand elektronisch eine Ergänzung der Tagesordnung beantragen.
            Über den Antrag entscheidet der Vorstand. Über Anträge zur
            Tagesordnung, die vom Vorstand nicht aufgenommen wurden oder die
            erstmals in der Mitgliederversammlung gestellt werden, entscheidet
            die Mitgliederversammlung mit der Mehrheit der Stimmen der
            anwesenden Mitglieder; dies gilt nicht für Anträge, die eine
            Änderung der Satzung, Änderungen der Mitgliedsbeiträge oder die
            Auflösung des Vereins zum Gegenstand haben.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Der Vorstand hat eine außerordentliche Mitgliederversammlung
            einzuberufen, wenn es das Interesse des Vereins erfordert oder wenn
            mindestens ein Viertel der Mitglieder dies schriftlich unter Angabe
            des Zwecks und der Gründe beantragt.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (4) Das Einladungsschreiben wird elektronisch versendet und gilt als
            dem Mitglied zugegangen, wenn es an die letzte vom Mitglied dem
            Verein bekannt gegebene E-Mail Adresse gerichtet ist.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 14 Beschlussfassung der Mitgliederversammlung
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Die Mitgliederversammlung wird vom Vorstand, bei dessen
            Verhinderung von einem durch die Mitgliederversammlung zu wählenden
            Versammlungsleiter geleitet.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Die Mitgliederversammlung ist beschlussfähig, wenn mindestens
            ein Drittel aller Vereinsmitglieder anwesend ist. Bei
            Beschlussunfähigkeit ist der Vorstand verpflichtet, innerhalb von
            vier Wochen eine zweite Mitgliederversammlung mit der gleichen
            Tagesordnung einzuberufen. Diese ist ohne Rücksicht auf die Zahl der
            erschienenen Mitglieder beschlussfähig. Hierauf ist in der Einladung
            hinzuweisen.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Die Mitgliederversammlung beschließt in einer offenen Abstimmung
            mit der Mehrheit der Stimmen der anwesenden Mitglieder. Kann bei
            Wahlen kein:e Kandidat:in die Mehrheit der Stimmen der anwesenden
            Mitglieder auf sich vereinen, ist gewählt, wer die Mehrheit der
            abgegebenen gültigen Stimmen erhalten hat; zwischen mehreren
            Kandidat:innen ist eine Stichwahl durchzuführen. Beschlüsse über
            eine Änderung der Satzung bedürfen der Mehrheit von drei Vierteln,
            die Beschlüsse über eine Änderung des Zwecks oder die Auflösung des
            Vereins der Zustimmung von neun Zehnteln der anwesenden Mitglieder.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (4) Über den Ablauf der Mitgliederversammlung und die gefassten
            Beschlüsse ist ein Protokoll zu erstellen, das von der
            Protokollführung und von der Versammlungsleitung zu unterschreiben
            ist.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (5) Für die außerordentliche Mitgliederversammlung gilt die
            Vereinssatzung.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 15 Auflösung des Vereins, Beendigung aus anderen Gründen
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (1) Im Falle der Auflösung des Vereins sind die Mitglieder des
            Vorstands gemeinsam vertretungsberechtigte Liquidator:innen, falls
            die Mitgliederversammlung keine anderen Personen beruft.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (2) Die Vereinsauflösung kann im Rahmen eines Beschlusses der
            Mitgliederversammlung mit neun Zehntel der Stimmen beschlossen
            werden.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (3) Bei Auflösung und Aufhebung des Vereins wird das Vermögen des
            Vereins zu zwei Dritteln an die Mitglieder gleichmäßig aufgeteilt.
            Das restliche Drittel erhält der Vorstand.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            (4) Die vorstehenden Bestimmungen gelten entsprechend, wenn dem
            Verein die Rechtsfähigkeit entzogen wurde.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 16 Unwirksamkeit von Teilen der Satzung
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0  }}>
            Bei Unwirksamkeit von Teilen der in der Satzung enthaltenen
            Bestimmungen bleibt der übrige Teil der Satzung voll wirksam.
          </p>
          {/* <iframe className="pdfviewer" src={Vereinssatzung2}></iframe>
          <iframe className="pdfviewer" src={Beitragsordnung}></iframe> */}
        </Box>
      </div>
    </HelmetProvider>
  );
};

export default Vereinssatzung;
