import { useState, useRef } from "react";
import {Text} from 'react-native';
import "../../App.css";
import supabase from "../../config/supabaseClient";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { styles } from "../../styles";

import Box from "@mui/material/Box";

const ResetPassword = () => {
    let navigate = useNavigate();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState("");
    const [email, setEMail] = useState("");

    async function handleSubmit(e) {
    e.preventDefault();

    try {
        let { data, error } = await supabase.auth.resetPasswordForEmail(email);

        if (error) throw error;
        console.log(data);
        navigate("/");
        alert('Dir wurde eine E-Mail zugesendet.')
    } catch (error) {
        alert(error);
        setErrMsg(error);
    }
    }

  return (
    <>
      <Text
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </Text>

      <Box sx={{margin: 5}}>
        <form className="formcontainer" onSubmit={handleSubmit}>
          <Text style={styles.titleText}>Passwort zurücksetzen {'\n'}</Text>
          <FormControl fullWidth sx={{ m: 1 / 2 }}>
            <TextField
              placeholder="E-Mail Addresse"
              name="email"
              onChange={(e) => {
                setEMail(e.target.value);
              }}

              fullWidth={true}
            />
          </FormControl>
          
          
          <Button type="submit" variant="contained" color="success">
            Passwort zurücksetzen
          </Button>
        </form>
      </Box>
    </>
  )
}

export default ResetPassword
