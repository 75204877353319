import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import supabase from "../../../config/supabaseClient.js";

export default function ChangeModelDialog(props) {
  const { model, id, memberId, columnIndex, members, setMembers, open, onClose } = props;

  const [newModel, setNewModel] = useState("");

  const handleChangeModel = async () => {
    try {
      if (id) {
        // Aktualisiere die lokale State-Variable
        setMembers((prevMembers) => {
          const updatedMembers = prevMembers.map((member) =>
            member.id === id
              ? {
                  ...member,
                  model: newModel,
                }
              : member
          );
          return updatedMembers;
        });

        // Aktualisiere die Daten in der supabase-Tabelle "User"
        const { data, error } = await supabase
          .from("User")
          .update({
            model: newModel,
          })
          .eq("id", id);

        if (error) {
          console.error("Fehler beim Aktualisieren des Vereinsmodells:", error);
          throw error;
        }
      }

      onClose(); // Schließe das Dialog-Fenster nach erfolgreicher Aktualisierung
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Vereinsmodells:", error);
      // Handle den Fehler entsprechend
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle> Vereinsmodell ändern</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" sx={{ marginTop: 1 }}>
              Vereinsmodell
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newModel}
              label="Vereinsmodell"
              sx={{ marginTop: 1 }}
              onChange={(e) => {
                setNewModel(e.target.value);
              }}
            >
              <MenuItem value={"Vereinspflege"}>Vereinspflege</MenuItem>
              <MenuItem value={"Privatpflege"}>Privatpflege</MenuItem>
              <MenuItem value={"Ehrenmitglied-Vereinspflege"}>Ehrenmitglied-Vereinspflege</MenuItem>
              <MenuItem value={"Ehrenmitglied-Privatpflege"}>Ehrenmitglied-Privatpflege</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Schließen</Button>
          <Button onClick={handleChangeModel}>Ändern</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
