import React from "react";
import { Text } from "react-native-web";
import { Box } from "@mui/system";
import { styles } from "../../styles";
import Paper from "@mui/material/Paper";

const Datenschutz = () => {
  return (
    <Box
      component={Paper}
      sx={{
        margin: "auto",
        marginTop: 5,
        textAlign: "center",
        width: "60%",
        padding: 10,
      }}
    >
      {/* <Text style={styles.titleText}>
        Deine Daten werden geschützt, Trust me Bro!
      </Text> */}
      <Text>
        <span style={styles.normalText}>
          <h1>Datenschutzerklärung</h1>
          <h2>Präambel</h2>
          <p>
            Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
            aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
            auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in
            welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle
            von uns durchgeführten Verarbeitungen personenbezogener Daten,
            sowohl im Rahmen der Erbringung unserer Leistungen als auch
            insbesondere auf unseren Webseiten, in mobilen Applikationen sowie
            innerhalb externer Onlinepräsenzen, wie z. B. unserer
            Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
            "Onlineangebot"). {"\n"}Die verwendeten Begriffe sind nicht
            geschlechtsspezifisch. {"\n"}
            Stand: 17. März 2024{"\n"}
          </p>
        </span>
        <h2>Inhaltsübersicht</h2>
        <p>
          • Präambel{"\n"}• Verantwortlicher{"\n"}• Kontakt
          Datenschutzbeauftragter{"\n"}• Übersicht der Verarbeitungen{"\n"}•
          Maßgebliche Rechtsgrundlagen{"\n"}• Sicherheitsmaßnahmen{"\n"}•
          Internationale Datentransfers{"\n"}• Löschung von Daten{"\n"}• Rechte
          der betroffenen Personen{"\n"}• Wahrnehmung von Aufgaben nach Satzung
          oder Geschäftsordnung{"\n"}• Bereitstellung des Onlineangebotes und
          Webhosting{"\n"}• Einsatz von Cookies{"\n"}• Registrierung, Anmeldung
          und Nutzerkonto{"\n"}• Kontakt- und Anfragenverwaltung{"\n"}• Umfragen
          und Befragungen{"\n"}• Webanalyse, Monitoring und Optimierung{"\n"}•
          Kundenrezensionen und Bewertungsverfahren{"\n"}• Präsenzen in sozialen
          Netzwerken (Social Media){"\n"}• Plugins und eingebettete Funktionen
          sowie Inhalte{"\n"}• Begriffsdefinitionen{"\n"}
        </p>
        <h2>Verantwortlicher</h2>
        <p>
          Hannes Lokatis{"\n"}
          Fössestraße 103 {"\n"}
          30453 Hannover {"\n"}
          E-Mail-Adresse: vorstand@hemp-heaven-hannover.de {"\n"}
        </p>
{/*         <h2>Kontakt Datenschutzbeauftragter</h2>
        <p>
          Hannes Lokatis{"\n"}
          Fössestraße 103 {"\n"}
          30453 Hannover {"\n"}
          E-Mail-Adresse: vorstand@hemp-heaven-hannover.de {"\n"}
        </p> */}
        <h2>Übersicht der Verarbeitungen</h2>
        <p>
          Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
          die Zwecke ihrer Verarbeitung zusammen und verweist auf die
          betroffenen Personen.
        </p>
        <h3>Arten der verarbeiteten Daten</h3>
        <p>
          • Bestandsdaten.{"\n"}• Zahlungsdaten.{"\n"}• Standortdaten.{"\n"}•
          Kontaktdaten.{"\n"}• Inhaltsdaten.{"\n"}• Vertragsdaten.{"\n"}•
          Nutzungsdaten.{"\n"}• Meta-, Kommunikations- und Verfahrensdaten.
          {"\n"}
        </p>
        <h3>Kategorien betroffener Personen</h3>
        <p>
          • Mitglieder.{"\n"}• Kommunikationspartner.{"\n"}• Nutzer.{"\n"}•
          Geschäfts- und Vertragspartner.{"\n"}• Teilnehmer.{"\n"}• Dienstleister.{"\n"}• Interessenten.
          {"\n"}
        </p>
        <h3>Zwecke der Verarbeitung</h3>
        <p>
          • Erbringung vertraglicher Leistungen und Erfüllung vertraglicher
          Pflichten.{"\n"}• Vorvertragliche Maßnahmen.{"\n"}• Kontaktanfragen und Kommunikation.{"\n"}•
          Sicherheitsmaßnahmen.{"\n"}• Reichweitenmessung.{"\n"}• Verwaltung und
          Beantwortung von Anfragen.{"\n"}• Feedback.{"\n"}• Marketing.{"\n"}•
          Profile mit nutzerbezogenen Informationen.{"\n"}• Bereitstellung
          unseres Onlineangebotes und Nutzerfreundlichkeit.{"\n"}•
          Informationstechnische Infrastruktur.{"\n"}
        </p>
        <h2>Maßgebliche Rechtsgrundlagen</h2>
        
          <p style={styles.boldText}>Maßgebliche Rechtsgrundlagen nach der DSGVO:</p><p>Im Folgenden erhalten Sie
          eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir
          personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis,
          dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in
          Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner
          im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir
          Ihnen diese in der Datenschutzerklärung mit.{"\n"}
        </p>
        <p>
          • Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO) - Die betroffene
          Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden
          personenbezogenen Daten für einen spezifischen Zweck oder mehrere
          bestimmte Zwecke gegeben. {"\n"}
        </p>
        <p>
          • Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
          lit. b) DSGVO) - Die Verarbeitung ist für die Erfüllung eines
          Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur
          Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage
          der betroffenen Person erfolgen. {"\n"}
        </p>
        <p>
          • Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO) - Die
          Verarbeitung ist zur Wahrung der berechtigten Interessen des
          Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
          Interessen oder Grundrechte und Grundfreiheiten der betroffenen
          Person, die den Schutz personenbezogener Daten erfordern, überwiegen.{" "}
          {"\n"}
        </p>
        <p>
          Nationale Datenschutzregelungen in Deutschland: Zusätzlich zu den
          Datenschutzregelungen der DSGVO gelten nationale Regelungen zum
          Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum
          Schutz vor Missbrauch personenbezogener Daten bei der
          Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
          insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
          Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
          Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke
          und zur Übermittlung sowie automatisierten Entscheidungsfindung im
          Einzelfall einschließlich Profiling. Ferner können
          Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
          gelangen.{"\n"}
        </p>
        <p>
          Hinweis auf Geltung DSGVO und Schweizer DSG: Diese Datenschutzhinweise
          dienen sowohl der Informationserteilung nach dem schweizerischen
          Bundesgesetz über den Datenschutz (Schweizer DSG) als auch nach der
          Datenschutzgrundverordnung (DSGVO). Aus diesem Grund bitten wir Sie zu
          beachten, dass aufgrund der breiteren räumlichen Anwendung und
          Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere
          statt der im Schweizer DSG verwendeten Begriffe „Bearbeitung" von
          „Personendaten", "überwiegendes Interesse" und "besonders
          schützenswerte Personendaten" werden die in der DSGVO verwendeten
          Begriffe „Verarbeitung" von „personenbezogenen Daten" sowie
          "berechtigtes Interesse" und "besondere Kategorien von Daten"
          verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch im
          Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer DSG
          bestimmt.{"\n"}
        </p>

        <h2>Sicherheitsmaßnahmen </h2>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
          Berücksichtigung des Stands der Technik, der Implementierungskosten
          und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
          sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
          Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen
          geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten.{"\n"}
          Zu den Maßnahmen gehören
          insbesondere die Sicherung der Vertraulichkeit, Integrität und
          Verfügbarkeit von Daten durch Kontrolle des physischen und
          elektronischen Zugangs zu den Daten als auch des sie betreffenden
          Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit
          und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die
          eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
          Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
          berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
          Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren
          entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung
          und durch datenschutzfreundliche Voreinstellungen.{"\n"}
        </p>
        <p>
          TLS/SSL-Verschlüsselung (https): Um die Daten der Benutzer, die über
          unsere Online-Dienste übertragen werden, zu schützen, verwenden wir
          TLS/SSL-Verschlüsselung. Secure Sockets Layer (SSL) ist die
          Standardtechnologie zur Sicherung von Internetverbindungen durch
          Verschlüsselung der zwischen einer Website oder App und einem Browser
          (oder zwischen zwei Servern) übertragenen Daten. Transport Layer
          Security (TLS) ist eine aktualisierte und sicherere Version von SSL.
          Hyper Text Transfer Protocol Secure (HTTPS) wird in der URL angezeigt,
          wenn eine Website durch ein SSL/TLS-Zertifikat gesichert ist.{"\n"}
        </p>

        <h2>Internationale Datentransfers</h2>
        <p>
          Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland
          (d. h., außerhalb der Europäischen Union (EU), des Europäischen
          Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen
          der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw.
          Übermittlung von Daten an andere Personen, Stellen oder Unternehmen
          stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen
          Vorgaben. Sofern das Datenschutzniveau in dem Drittland mittels eines
          Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient
          dieser als Grundlage des Datentransfers. Im Übrigen erfolgen
          Datentransfers nur dann, wenn das Datenschutzniveau anderweitig
          gesichert ist, insbesondere durch Standardvertragsklauseln (Art. 46
          Abs. 2 lit. c) DSGVO), ausdrückliche Einwilligung oder im Fall
          vertraglicher oder gesetzlich erforderlicher Übermittlung (Art. 49
          Abs. 1 DSGVO). Im Übrigen teilen wir Ihnen die Grundlagen der
          Drittlandübermittlung bei den einzelnen Anbietern aus dem Drittland
          mit, wobei die Angemessenheitsbeschlüsse als Grundlagen vorrangig
          gelten. Informationen zu Drittlandtransfers und vorliegenden
          Angemessenheitsbeschlüssen können dem Informationsangebot der
          EU-Kommission entnommen werden:{" "}
          <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de">https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de</a>.
          {"\n"}
        </p>
        <p>
          EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten
          „Data Privacy Framework" (DPF) hat die EU-Kommission das
          Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den USA im
          Rahmen der Angemessenheitsbeschlusses vom 10.07.2023 als sicher
          anerkannt. Die Liste der zertifizierten Unternehmen als auch weitere
          Informationen zu dem DPF können Sie der Webseite des
          Handelsministeriums der USA unter {" "}
          <a href="https://www.dataprivacyframework.gov/">https://www.dataprivacyframework.gov/ </a>(in Englisch) entnehmen. Wir
          informieren Sie im Rahmen der Datenschutzhinweise, welche von uns
          eingesetzten Diensteanbieter unter dem Data Privacy Framework
          zertifiziert sind.{"\n"}
        </p>

        <h2>Löschung von Daten </h2>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
          Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
          Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
          (z. B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder
          sie für den Zweck nicht erforderlich sind). Sofern die Daten nicht
          gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
          erforderlich sind, wird deren Verarbeitung auf diese Zwecke
          beschränkt. D. h., die Daten werden gesperrt und nicht für andere
          Zwecke verarbeitet. Das gilt z. B. für Daten, die aus handels- oder
          steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
          Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
          oder juristischen Person erforderlich ist. Unsere Datenschutzhinweise
          können ferner weitere Angaben zu der Aufbewahrung und Löschung von
          Daten beinhalten, die für die jeweiligen Verarbeitungen vorrangig
          gelten.{"\n"}
        </p>

        <h2>Rechte der betroffenen Personen </h2>
        <p>
          Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
          Betroffene nach der DSGVO verschiedene Rechte zu, die sich
          insbesondere aus Art. 15 bis 21 DSGVO ergeben: {"\n"}
        </p>
        <p>
          • Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
          Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
          der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6
          Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
          auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die
          Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung
          zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
          Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
          derartiger Werbung einzulegen; dies gilt auch für das Profiling,
          soweit es mit solcher Direktwerbung in Verbindung steht. {"\n"}•
          Widerrufsrecht bei Einwilligungen: Sie haben das Recht, erteilte
          Einwilligungen jederzeit zu widerrufen. {"\n"}• Auskunftsrecht: Sie
          haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende
          Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf
          weitere Informationen und Kopie der Daten entsprechend den
          gesetzlichen Vorgaben. {"\n"}• Recht auf Berichtigung: Sie haben
          entsprechend den gesetzlichen Vorgaben das Recht, die
          Vervollständigung der Sie betreffenden Daten oder die Berichtigung der
          Sie betreffenden unrichtigen Daten zu verlangen. {"\n"}• Recht auf
          Löschung und Einschränkung der Verarbeitung: Sie haben nach Maßgabe
          der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie
          betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach
          Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung
          der Daten zu verlangen. {"\n"}• Recht auf Datenübertragbarkeit: Sie
          haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt
          haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten,
          gängigen und maschinenlesbaren Format zu erhalten oder deren
          Übermittlung an einen anderen Verantwortlichen zu fordern. {"\n"}•
          Beschwerde bei Aufsichtsbehörde: Sie haben unbeschadet eines
          anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs
          das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in
          dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres
          Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der
          Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.{"\n"}
        </p>

        <h2>Wahrnehmung von Aufgaben nach Satzung oder Geschäftsordnung</h2>
        <p>
          Wir verarbeiten die Daten unserer Mitglieder, Unterstützer,
          Interessenten, Geschäftspartner oder sonstiger Personen
          (Zusammenfassend "Betroffene"), wenn wir mit ihnen in einem
          Mitgliedschafts- oder sonstigem geschäftlichen Verhältnis stehen und
          unsere Aufgaben wahrnehmen sowie Empfänger von Leistungen und
          Zuwendungen sind. Im Übrigen verarbeiten wir die Daten Betroffener auf
          Grundlage unserer berechtigten Interessen, z. B. wenn es sich um
          administrative Aufgaben oder Öffentlichkeitsarbeit handelt. {"\n"}
        </p>
        <p>
          Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und
          die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem
          zugrundeliegenden Mitgliedschafts- oder Vertragsverhältnis, aus dem
          sich auch die Erforderlichkeit etwaiger Datenangaben ergeben (im
          Übrigen weisen wir auf erforderliche Daten hin). {"\n"}
        </p>
        <p>
          Wir löschen Daten, die zur Erbringung unserer satzungs- und
          geschäftsmäßigen Zwecke nicht mehr erforderlich sind. Dies bestimmt
          sich entsprechend der jeweiligen Aufgaben und vertraglichen
          Beziehungen. Wir bewahren die Daten so lange auf, wie sie zur
          Geschäftsabwicklung, als auch im Hinblick auf etwaige Gewährleistungs-
          oder Haftungspflichten auf Grundlage unserer berechtigten Interesse an
          deren Regelung relevant sein können. Die Erforderlichkeit der
          Aufbewahrung der Daten wird regelmäßig überprüft; im Übrigen gelten
          die gesetzlichen Aufbewahrungspflichten. {"\n"}
        </p>
        <p>
          • Verarbeitete Datenarten: Bestandsdaten (z. B. Namen, Adressen);
          Zahlungsdaten (z. B. Bankverbindungen, Rechnungen, Zahlungshistorie);
          Kontaktdaten (z. B. E-Mail, Telefonnummern). Vertragsdaten (z. B.
          Vertragsgegenstand, Laufzeit, Kundenkategorie). {"\n"}• Betroffene
          Personen: Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten);
          Mitglieder. Geschäfts- und Vertragspartner. {"\n"}• Zwecke der
          Verarbeitung: Erbringung vertraglicher Leistungen und Erfüllung
          vertraglicher Pflichten; Kontaktanfragen und Kommunikation. Verwaltung
          und Beantwortung von Anfragen. {"\n"}• Rechtsgrundlagen:
          Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
          lit. b) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
          DSGVO). {"\n"}
        </p>

        <h2>Bereitstellung des Onlineangebotes und Webhosting</h2>
        <p>
          Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste
          zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
          IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und
          Funktionen unserer Online-Dienste an den Browser oder das Endgerät der
          Nutzer zu übermitteln. {"\n"}
        </p>
        <p>
          • Verarbeitete Datenarten: Nutzungsdaten (z. B. besuchte Webseiten,
          Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations- und
          Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus).{"\n"}• Betroffene
          Personen: Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).{" "}
          {"\n"}• Zwecke der Verarbeitung: Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
          Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
          technischen Geräten (Computer, Server etc.).). Sicherheitsmaßnahmen. •
          Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
          DSGVO).{"\n"}
        </p>
        <p>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          {"\n"}
        </p>
        <p>
          • Bereitstellung Onlineangebot auf gemietetem Speicherplatz: Für die
          Bereitstellung unseres Onlineangebotes nutzen wir Speicherplatz,
          Rechenkapazität und Software, die wir von einem entsprechenden
          Serveranbieter (auch "Webhoster" genannt) mieten oder anderweitig
          beziehen; Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1
          lit. f) DSGVO). {"\n"}• Erhebung von Zugriffsdaten und Logfiles: Der
          Zugriff auf unser Onlineangebot wird in Form von so genannten
          "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die
          Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
          Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
          erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
          Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall
          IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles
          können zum einen zu Zwecken der Sicherheit eingesetzt werden, z. B.,
          um eine Überlastung der Server zu vermeiden (insbesondere im Fall von
          missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
          anderen, um die Auslastung der Server und ihre Stabilität
          sicherzustellen; Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs.
          1 S. 1 lit. f) DSGVO). Löschung von Daten: Logfile-Informationen
          werden für die Dauer von maximal 30 Tagen gespeichert und danach
          gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu
          Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
          jeweiligen Vorfalls von der Löschung ausgenommen. {"\n"}
        </p>

        <h2>Einsatz von Cookies </h2>
        <p>
          Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die
          Informationen auf Endgeräten speichern und Informationen aus den
          Endgeräten auslesen. Z. B. um den Login-Status in einem Nutzerkonto,
          einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder
          verwendete Funktionen eines Onlineangebotes speichern. Cookies können
          ferner zu unterschiedlichen Zwecken eingesetzt werden, z. B. zu
          Zwecken der Funktionsfähigkeit, Sicherheit und Komfort von
          Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.{" "}
          {"\n"}
        </p>
        <p>
          Hinweise zur Einwilligung: Wir setzen Cookies im Einklang mit den
          gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine
          vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht
          gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig,
          wenn das Speichern und das Auslesen der Informationen, also auch von
          Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von
          ihnen ausdrücklich gewünschten Telemediendienst (also unser
          Onlineangebot) zur Verfügung zu stellen. Zu den unbedingt
          erforderlichen Cookies gehören in der Regel Cookies mit Funktionen,
          die der Anzeige und Lauffähigkeit des Onlineangebotes , dem
          Lastausgleich, der Sicherheit, der Speicherung der Präferenzen und
          Auswahlmöglichkeiten der Nutzer oder ähnlichen mit der Bereitstellung
          der Haupt- und Nebenfunktionen des von den Nutzern angeforderten
          Onlineangebotes zusammenhängenden Zwecken dienen. Die widerrufliche
          Einwilligung wird gegenüber den Nutzern deutlich kommuniziert und
          enthält die Informationen zu der jeweiligen Cookie-Nutzung. {"\n"}
        </p>
        <p>
          Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: Auf welcher
          datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen Daten
          der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir
          Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist
          die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte
          Einwilligung. Andernfalls werden die mithilfe von Cookies
          verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.
          B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes
          und Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im
          Rahmen der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der
          Einsatz von Cookies erforderlich ist, um unsere vertraglichen
          Verpflichtungen zu erfüllen. Zu welchen Zwecken die Cookies von uns
          verarbeitet werden, darüber klären wir im Laufe dieser
          Datenschutzerklärung oder im Rahmen von unseren Einwilligungs- und
          Verarbeitungsprozessen auf.{"\n"}
        </p>
        <p>
          Speicherdauer: Im Hinblick auf die Speicherdauer werden die folgenden
          Arten von Cookies unterschieden: {"\n"}
        </p>
        <p>
          • Temporäre Cookies (auch: Session- oder Sitzungs-Cookies): Temporäre
          Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
          Online-Angebot verlassen und sein Endgerät (z. B. Browser oder mobile
          Applikation) geschlossen hat. {"\n"}• Permanente Cookies: Permanente
          Cookies bleiben auch nach dem Schließen des Endgerätes gespeichert. So
          können beispielsweise der Login-Status gespeichert oder bevorzugte
          Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut
          besucht. Ebenso können die mit Hilfe von Cookies erhobenen Daten der
          Nutzer zur Reichweitenmessung verwendet werden. Sofern wir Nutzern
          keine expliziten Angaben zur Art und Speicherdauer von Cookies
          mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten
          Nutzer davon ausgehen, dass Cookies permanent sind und die
          Speicherdauer bis zu zwei Jahre betragen kann. {"\n"}
        </p>
        <p>
          Allgemeine Hinweise zum Widerruf und Widerspruch (sog. "Opt-Out"):
          Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
          widerrufen und der Verarbeitung entsprechend den gesetzlichen Vorgaben
          widersprechen. Hierzu können Nutzer unter anderem die Verwendung von
          Cookies in den Einstellungen ihres Browsers einschränken (wobei
          dadurch auch die Funktionalität unseres Onlineangebotes eingeschränkt
          sein kann). Ein Widerspruch gegen die Verwendung von Cookies zu
          Online-Marketing-Zwecken kann auch über die Websites{" "}
          <a href="https://optout.aboutads.info">https://optout.aboutads.info</a>{" "} und {" "}<a href="https://www.youronlinechoices.com/">https://www.youronlinechoices.com/</a>{" "}
          erklärt werden. {"\n"}
        </p>
        <p>
          • Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
          DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).{"\n"}
        </p>
        <p>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:{" "}
          {"\n"}
        </p>
        <p>
          • Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: Wir
          setzen eine Einwilligungs-Management-Lösung ein, bei der die
          Einwilligung der Nutzer zur Verwendung von Cookies oder zu den im
          Rahmen der Einwilligungs-Management-Lösung genannten Verfahren und
          Anbietern eingeholt wird. Dieses Verfahren dient der Einholung,
          Protokollierung, Verwaltung und dem Widerruf von Einwilligungen,
          insbesondere bezogen auf den Einsatz von Cookies und vergleichbaren
          Technologien, die zur Speicherung, zum Auslesen und zur Verarbeitung
          von Informationen auf den Endgeräten der Nutzer eingesetzt werden. Im
          Rahmen dieses Verfahrens werden die Einwilligungen der Nutzer für die
          Nutzung von Cookies und die damit verbundenen Verarbeitungen von
          Informationen, einschließlich der im
          Einwilligungs-Management-Verfahren genannten spezifischen
          Verarbeitungen und Anbieter, eingeholt. Die Nutzer haben zudem die
          Möglichkeit, ihre Einwilligungen zu verwalten und zu widerrufen. Die
          Einwilligungserklärungen werden gespeichert, um eine erneute Abfrage
          zu vermeiden und den Nachweis der Einwilligung gemäß der gesetzlichen
          Anforderungen führen zu können. Die Speicherung erfolgt serverseitig
          und/oder in einem Cookie (sogenanntes Opt-In-Cookie) oder mittels
          vergleichbarer Technologien, um die Einwilligung einem spezifischen
          Nutzer oder dessen Gerät zuordnen zu können. Sofern keine spezifischen
          Angaben zu den Anbietern von Einwilligungs-Management-Diensten
          vorliegen, gelten folgende allgemeine Hinweise: Die Dauer der
          Speicherung der Einwilligung beträgt bis zu zwei Jahre. Dabei wird ein
          pseudonymer Nutzer-Identifikator erstellt, der zusammen mit dem
          Zeitpunkt der Einwilligung, den Angaben zum Umfang der Einwilligung
          (z. B. betreffende Kategorien von Cookies und/oder Diensteanbieter)
          sowie Informationen über den Browser, das System und das verwendete
          Endgerät gespeichert wird; Rechtsgrundlagen: Einwilligung (Art. 6 Abs.
          1 S. 1 lit. a) DSGVO). {"\n"}
        </p>

        <h2>Registrierung, Anmeldung und Nutzerkonto</h2>
        <p>
          Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung
          werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu
          Zwecken der Bereitstellung des Nutzerkontos auf Grundlage
          vertraglicher Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten
          gehören insbesondere die Login-Informationen (Nutzername, Passwort
          sowie eine E-Mail-Adresse). {"\n"}
        </p>
        <p>
          Im Rahmen der Inanspruchnahme unserer Registrierungs- und
          Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die
          IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
          Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als
          auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger
          unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
          grundsätzlich nicht, es sei denn, sie ist zur Verfolgung unserer
          Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung
          hierzu. {"\n"}
        </p>
        <p>
          Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant
          sind, wie z. B. technische Änderungen, per E-Mail informiert werden.{" "}
          {"\n"}
        </p>
        <p>
          • Verarbeitete Datenarten: Bestandsdaten (z. B. Namen, Adressen);
          Kontaktdaten (z. B. E-Mail, Telefonnummern); Inhaltsdaten (z. B.
          Eingaben in Onlineformularen). Meta-, Kommunikations- und
          Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus). {"\n"}• Betroffene
          Personen: Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).{" "}
          {"\n"}• Zwecke der Verarbeitung: Erbringung vertraglicher Leistungen
          und Erfüllung vertraglicher Pflichten; Sicherheitsmaßnahmen;
          Verwaltung und Beantwortung von Anfragen. Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit. {"\n"}• Rechtsgrundlagen:
          Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
          lit. b) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
          DSGVO). {"\n"}
        </p>
        <p>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          {"\n"}
        </p>
        <p>
          • Registrierung mit Klarnamen: Aufgrund der Natur unserer Community
          bitten wir die Nutzer unser Angebot nur unter Verwendung von Klarnamen
          zu nutzen. D. h. die Nutzung von Pseudonymen ist nicht zulässig;
          Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen (Art.
          6 Abs. 1 S. 1 lit. b) DSGVO). {"\n"}• Profile der Nutzer sind nicht
          öffentlich: Die Profile der Nutzer sind öffentlich nicht sichtbar und
          nicht zugänglich.{"\n"}
        </p>

        <h2>Kontakt- und Anfragenverwaltung </h2>
        <p>
          Bei der Kontaktaufnahme mit uns (z. B. per Post, Kontaktformular,
          E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender
          Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden
          Personen verarbeitet soweit dies zur Beantwortung der Kontaktanfragen
          und etwaiger angefragter Maßnahmen erforderlich ist. {"\n"}
        </p>
        <p>
          • Verarbeitete Datenarten: Kontaktdaten (z. B. E-Mail,
          Telefonnummern); Inhaltsdaten (z. B. Eingaben in Onlineformularen);
          Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B.
          IP-Adressen, Zeitangaben, Identifikationsnummern,
          Einwilligungsstatus). {"\n"}• Betroffene Personen:
          Kommunikationspartner. {"\n"}• Zwecke der Verarbeitung:
          Kontaktanfragen und Kommunikation; Verwaltung und Beantwortung von
          Anfragen; Feedback (z. B. Sammeln von Feedback via Online-Formular).
          Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.{" "}
          {"\n"}• Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1
          lit. f) DSGVO). Vertragserfüllung und vorvertragliche Anfragen (Art. 6
          Abs. 1 S. 1 lit. b) DSGVO). {"\n"}
        </p>
        <p>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:{" "}
          {"\n"}
        </p>
        <p>
          • Kontaktformular: Wenn Nutzer über unser Kontaktformular, E-Mail oder
          andere Kommunikationswege mit uns in Kontakt treten, verarbeiten wir
          die uns in diesem Zusammenhang mitgeteilten Daten zur Bearbeitung des
          mitgeteilten Anliegens; Rechtsgrundlagen: Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).{"\n"}
        </p>

        <h2>Umfragen und Befragungen </h2>
        <p>
          Wir führen Umfragen und Befragungen durch, um Informationen für den
          jeweils kommunizierten Umfrage- bzw. Befragungszweck, zu sammeln. Die
          von uns durchgeführten Umfragen und Befragungen (nachfolgend
          "Befragungen") werden anonym ausgewertet. Eine Verarbeitung
          personenbezogener Daten erfolgt nur insoweit, als dies zu
          Bereitstellung und technischen Durchführung der Umfragen erforderlich
          ist (z. B. Verarbeitung der IP-Adresse, um die Umfrage im Browser des
          Nutzers darzustellen oder mithilfe eines Cookies eine Wiederaufnahme
          der Umfrage zu ermöglichen). {"\n"}
        </p>
        <p>
          • Verarbeitete Datenarten: Kontaktdaten (z. B. E-Mail,
          Telefonnummern); Inhaltsdaten (z. B. Eingaben in Onlineformularen);
          Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B.
          IP-Adressen, Zeitangaben, Identifikationsnummern,
          Einwilligungsstatus). {"\n"}• Betroffene Personen:
          Kommunikationspartner. Teilnehmer. {"\n"}• Zwecke der Verarbeitung:
          Feedback (z. B. Sammeln von Feedback via Online-Formular). {"\n"}•
          Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
          DSGVO). {"\n"}
        </p>

        {/* <h2>Webanalyse, Monitoring und Optimierung </h2>
        <p>
          Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der
          Auswertung der Besucherströme unseres Onlineangebotes und kann
          Verhalten, Interessen oder demographische Informationen zu den
          Besuchern, wie z. B. das Alter oder das Geschlecht, als pseudonyme
          Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z. B.
          erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen
          oder Inhalte am häufigsten genutzt werden oder zur Wiederverwendung
          einladen. Ebenso können wir nachvollziehen, welche Bereiche der
          Optimierung bedürfen. {"\n"}
        </p>
        <p>
          Neben der Webanalyse können wir auch Testverfahren einsetzen, um z. B.
          unterschiedliche Versionen unseres Onlineangebotes oder seiner
          Bestandteile zu testen und optimieren.{"\n"}
        </p>
        <p>
          Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken
          Profile, d. h. zu einem Nutzungsvorgang zusammengefasste Daten
          angelegt und Informationen in einem Browser, bzw. in einem Endgerät
          gespeichert und aus diesem ausgelesen werden. Zu den erhobenen Angaben
          gehören insbesondere besuchte Webseiten und dort genutzte Elemente
          sowie technische Angaben, wie der verwendete Browser, das verwendete
          Computersystem sowie Angaben zu Nutzungszeiten. Sofern Nutzer in die
          Erhebung ihrer Standortdaten uns gegenüber oder gegenüber den
          Anbietern der von uns eingesetzten Dienste einverstanden erklärt
          haben, können auch Standortdaten verarbeitet werden. {"\n"}
        </p>
        <p>
          Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
          nutzen wir ein IP-Masking-Verfahren (d. h., Pseudonymisierung durch
          Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die im
          Rahmen von Webanalyse, A/B-Testings und Optimierung keine Klardaten
          der Nutzer (wie z. B. E-Mail-Adressen oder Namen) gespeichert, sondern
          Pseudonyme. D. h., wir als auch die Anbieter der eingesetzten Software
          kennen nicht die tatsächliche Identität der Nutzer, sondern nur den
          für Zwecke der jeweiligen Verfahren in deren Profilen gespeicherten
          Angaben. {"\n"}
        </p>
        <p>
          • Verarbeitete Datenarten: Nutzungsdaten (z. B. besuchte Webseiten,
          Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations- und
          Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus). {"\n"}• Betroffene
          Personen: Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).{" "}
          {"\n"}• Zwecke der Verarbeitung: Reichweitenmessung (z. B.
          Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Profile mit
          nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
          Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.{" "}
          {"\n"}• Sicherheitsmaßnahmen: IP-Masking (Pseudonymisierung der
          IP-Adresse). {"\n"}• Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S.
          1 lit. a) DSGVO). {"\n"}
        </p>
        <p>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          {"\n"}
        </p>
        <p>
          • Google Analytics: Wir verwenden Google Analytics zur Messung und
          Analyse der Nutzung unseres Onlineangebotes auf der Grundlage einer
          pseudonymen Nutzeridentifikationsnummer. Diese Identifikationsnummer
          enthält keine eindeutigen Daten, wie Namen oder E-Mail-Adressen. Sie
          dient dazu, Analyseinformationen einem Endgerät zuzuordnen, um zu
          erkennen, welche Inhalte die Nutzer innerhalb eines oder verschiedener
          Nutzungsvorgänge aufgerufen haben, welche Suchbegriffe sie verwendet
          haben, diese erneut aufgerufen haben oder mit unserem Onlineangebot
          interagiert haben. Ebenso werden der Zeitpunkt der Nutzung und deren
          Dauer gespeichert, sowie die Quellen der Nutzer, die auf unser
          Onlineangebot verweisen und technische Aspekte ihrer Endgeräte und
          Browser. Dabei werden pseudonyme Profile von Nutzern mit Informationen
          aus der Nutzung verschiedener Geräte erstellt, wobei Cookies
          eingesetzt werden können. Google Analytics protokolliert und speichert
          keine individuellen IP-Adressen für EU-Nutzer. Analytics stellt jedoch
          grobe geografische Standortdaten bereit, indem es die folgenden
          Metadaten von IP-Adressen ableitet: Stadt (und der abgeleitete
          Breiten- und Längengrad der Stadt), Kontinent, Land, Region,
          Subkontinent (und ID-basierte Gegenstücke). Beim EU-Datenverkehr
          werden die IP-Adressdaten ausschließlich für diese Ableitung von
          Geolokalisierungsdaten verwendet, bevor sie sofort gelöscht werden.
          Sie werden nicht protokolliert, sind nicht zugänglich und werden nicht
          für weitere Verwendungszwecke genutzt. Wenn Google Analytics Messdaten
          sammelt, werden alle IP-Abfragen auf EU-basierten Servern
          durchgeführt, bevor der Verkehr zur Verarbeitung an Analytics-Server
          weitergeleitet wird; Dienstanbieter: Google Ireland Limited, Gordon
          House, Barrow Street, Dublin 4, Irland; Rechtsgrundlagen: Einwilligung
          (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); {"\n"}
          Website:{" "}
          <a href="https://marketingplatform.google.com/intl/de/about/analytics/">https://marketingplatform.google.com/intl/de/about/analytics/</a>;{"\n"}
          Sicherheitsmaßnahmen: IP-Masking (Pseudonymisierung der IP-Adresse);{"\n"}
          Datenschutzerklärung: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>;{"\n"}
          Auftragsverarbeitungsvertrag:
          <a href="https://business.safety.google/adsprocessorterms/">https://business.safety.google/adsprocessorterms/</a>; {"\n"}
          Grundlage Drittlandtransfers: Data Privacy Framework (DPF);{"\n"}
          Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>, {"\n"}
          Einstellungen für die Darstellung von Werbeeinblendungen:
          <a href="https://myadcenter.google.com/personalizationoff">https://myadcenter.google.com/personalizationoff</a>. {"\n"}
          Weitere Informationen: <a href="https://business.safety.google/adsservices/">https://business.safety.google/adsservices/</a> (Arten der
          Verarbeitung sowie der verarbeiteten Daten). {"\n"}
        </p>

        <h2>Kundenrezensionen und Bewertungsverfahren </h2>
        <p>
          Wir nehmen an Rezensions- und Bewertungsverfahren teil, um unsere
          Leistungen zu evaluieren, zu optimieren und zu bewerben. Wenn Nutzer
          uns über die beteiligten Bewertungsplattformen oder -verfahren
          bewerten oder anderweitig Feedback geben, gelten zusätzlich die
          Allgemeinen Geschäfts- oder Nutzungsbedingungen und die
          Datenschutzhinweise der Anbieter. Im Regelfall setzt die Bewertung
          zudem eine Registrierung bei den jeweiligen Anbietern voraus.{"\n"}
        </p>
        <p>
          Um sicherzustellen, dass die bewertenden Personen tatsächlich unsere
          Leistungen in Anspruch genommen haben, übermitteln wir mit
          Einwilligung der Kunden die hierzu erforderlichen Daten im Hinblick
          auf den Kunden und die in Anspruch genommene Leistung an die jeweilige
          Bewertungsplattform (einschließlich Name, E-Mail-Adresse und
          Bestellnummer bzw. Artikelnummer). Diese Daten werden alleine zur
          Verifizierung der Authentizität des Nutzers verwendet. {"\n"}
        </p>
        <p>
          • Verarbeitete Datenarten: Vertragsdaten (z. B. Vertragsgegenstand,
          Laufzeit, Kundenkategorie); Nutzungsdaten (z. B. besuchte Webseiten,
          Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations- und
          Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus). {"\n"}• Betroffene
          Personen: Kunden. Nutzer (z. B. Webseitenbesucher, Nutzer von
          Onlinediensten). {"\n"}• Zwecke der Verarbeitung: Feedback (z. B.
          Sammeln von Feedback via Online-Formular). Marketing. {"\n"}•
          Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
          DSGVO). {"\n"}
        </p>
        <p>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:{" "}
          {"\n"}
        </p>
        <p>
          • Bewertungs-Widget: Wir binden in unser Onlineangebot sogenannte
          "Bewertungs-Widgets" ein. Ein Widget ist ein in unser Onlineangebot
          eingebundenes Funktions- und Inhaltselement, das veränderliche
          Informationen anzeigt. Es kann z. B. in Form eines Siegels oder
          vergleichbaren Elements, zum Teil auch "Badge" genannt, dargestellt
          werden. Dabei wird der entsprechende Inhalt des Widgets zwar innerhalb
          unseres Onlineangebotes dargestellt, er wird aber in diesem Moment von
          den Servern des jeweiligen Widgets-Anbieters abgerufen. Nur so kann
          immer der aktuelle Inhalt gezeigt werden, vor allem die jeweils
          aktuelle Bewertung. Dafür muss eine Datenverbindung von der innerhalb
          unseres Onlineangebotes aufgerufenen Webseite zu dem Server des
          Widgets-Anbieters aufgebaut werden und der Widgets-Anbieter erhält
          gewisse technische Daten (Zugriffsdaten, inklusive IP-Adresse), die
          nötig sind, damit der Inhalt des Widgets an den Browser des Nutzers
          ausgeliefert werden kann. Des Weiteren erhält der Widgets-Anbieter
          Informationen darüber, dass Nutzer unser Onlineangebot besucht haben.
          Diese Informationen können in einem Cookie gespeichert und von dem
          Widgets-Anbieter verwendet werden, um zu erkennen, welche
          Onlineangebote, die am dem Bewertungsverfahren teilnehmen, von dem
          Nutzer besucht worden sind. Die Informationen können in einem
          Nutzerprofil gespeichert und für Werbe- oder Marktforschungszwecke
          verwendet werden; Rechtsgrundlagen: Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO). {"\n"}
        </p> */}

        <h2>Präsenzen in sozialen Netzwerken (Social Media)</h2>
        <p>
          Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
          verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven
          Nutzern zu kommunizieren oder um Informationen über uns anzubieten.{" "}
          {"\n"}
        </p>
        <p>
          Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
          Raumes der Europäischen Union verarbeitet werden können. Hierdurch
          können sich für die Nutzer Risiken ergeben, weil so z. B. die
          Durchsetzung der Rechte der Nutzer erschwert werden könnte. {"\n"}
        </p>
        <p>
          Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
          Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können
          z. B. anhand des Nutzungsverhaltens und sich daraus ergebender
          Interessen der Nutzer Nutzungsprofile erstellt werden. Die
          Nutzungsprofile können wiederum verwendet werden, um z. B.
          Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
          mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken
          werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert,
          in denen das Nutzungsverhalten und die Interessen der Nutzer
          gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
          unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
          (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen
          sind und bei diesen eingeloggt sind). {"\n"}
        </p>
        <p>
          Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen
          und der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
          Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
          Netzwerke. {"\n"}
        </p>
        <p>
          Auch im Fall von Auskunftsanfragen und der Geltendmachung von
          Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten
          bei den Anbietern geltend gemacht werden können. Nur die Anbieter
          haben jeweils Zugriff auf die Daten der Nutzer und können direkt
          entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
          dennoch Hilfe benötigen, dann können Sie sich an uns wenden. {"\n"}
        </p>
        <p>
          • Verarbeitete Datenarten: Kontaktdaten (z. B. E-Mail,
          Telefonnummern); Inhaltsdaten (z. B. Eingaben in Onlineformularen);
          Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B.
          IP-Adressen, Zeitangaben, Identifikationsnummern,
          Einwilligungsstatus). {"\n"}• Betroffene Personen: Nutzer (z. B.
          Webseitenbesucher, Nutzer von Onlinediensten). {"\n"}• Zwecke der
          Verarbeitung: Kontaktanfragen und Kommunikation; Feedback (z. B.
          Sammeln von Feedback via Online-Formular). Marketing.{"\n"}•
          Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
          DSGVO). {"\n"}
        </p>
        <p>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          {"\n"}
        </p>
        <p>
          • Instagram: Soziales Netzwerk; Dienstanbieter: Meta Platforms Ireland
          Limited, Merrion Road, Dublin 4, D04 X2K5, Irland; {"\n"}
          Rechtsgrundlagen:
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);{"\n"} 
          Website:{" "}<a href="https://www.instagram.com">https://www.instagram.com</a>; {"\n"}
          Datenschutzerklärung:{" "}<a href="https://instagram.com/about/legal/privacy">https://instagram.com/about/legal/privacy</a>. {"\n"}
          Grundlage Drittlandtransfers: Data Privacy Framework (DPF). {"\n"}
        </p>

        <h2>Plugins und eingebettete Funktionen sowie Inhalte</h2>

        <p>
          Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein,
          die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet
          als "Drittanbieter") bezogen werden. Dabei kann es sich zum Beispiel
          um Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich
          bezeichnet als "Inhalte"). {"\n"}
        </p>
        <p>
          Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
          Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
          IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
          IP-Adresse ist damit für die Darstellung dieser Inhalte oder
          Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
          verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
          Auslieferung der Inhalte verwenden. Drittanbieter können ferner
          sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
          bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
          "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
          Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
          Informationen können ferner in Cookies auf dem Gerät der Nutzer
          gespeichert werden und unter anderem technische Informationen zum
          Browser und zum Betriebssystem, zu verweisenden Webseiten, zur
          Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
          enthalten als auch mit solchen Informationen aus anderen Quellen
          verbunden werden. {"\n"}
        </p>
        <p>
          • Verarbeitete Datenarten: Nutzungsdaten (z. B. besuchte Webseiten,
          Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und
          Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus). Standortdaten (Angaben
          zur geografischen Position eines Gerätes oder einer Person). {"\n"}•
          Betroffene Personen: Nutzer (z. B. Webseitenbesucher, Nutzer von
          Onlinediensten). {"\n"}• Zwecke der Verarbeitung: Bereitstellung
          unseres Onlineangebotes und Nutzerfreundlichkeit; Marketing. Profile
          mit nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).{" "}
          {"\n"}• Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1
          lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).{" "}
          {"\n"}
        </p>
        <p>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:{" "}
          {"\n"}
        </p>
        <p>
          • Einbindung von Drittsoftware, Skripten oder Frameworks (z. B.
          jQuery): Wir binden in unser Onlineangebot Software ein, die wir von
          Servern anderer Anbieter abrufen (z. B. Funktions-Bibliotheken, die
          wir zwecks Darstellung oder Nutzerfreundlichkeit unseres
          Onlineangebotes verwenden). Hierbei erheben die jeweiligen Anbieter
          die IP-Adresse der Nutzer und können diese zu Zwecken der Übermittlung
          der Software an den Browser der Nutzer sowie zu Zwecken der
          Sicherheit, als auch zur Auswertung und Optimierung ihres Angebotes
          verarbeiten. - Wir binden in unser Onlineangebot Software ein, die wir
          von Servern anderer Anbieter abrufen (z. B. Funktions-Bibliotheken,
          die wir zwecks Darstellung oder Nutzerfreundlichkeit unseres
          Onlineangebotes verwenden). Hierbei erheben die jeweiligen Anbieter
          die IP-Adresse der Nutzer und können diese zu Zwecken der Übermittlung
          der Software an den Browser der Nutzer sowie zu Zwecken der
          Sicherheit, als auch zur Auswertung und Optimierung ihres Angebotes
          verarbeiten; Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1
          S. 1 lit. f) DSGVO). {"\n"}
          • Google Fonts (Bereitstellung auf eigenem
          Server): Bereitstellung von Schriftarten-Dateien (und Symbolen) zwecks einer
          nutzerfreundlichen Darstellung unseres Onlineangebotes;{"\n"}
          Dienstanbieter: Die Google Fonts werden auf unserem Server gehostet,
          es werden keine Daten an Google übermittelt; {"\n"}
          Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). {"\n"}
          
          • Google Maps:
          Wir binden die Landkarten des Dienstes "Google Maps" des Anbieters
          Google ein. Zu den verarbeiteten Daten können insbesondere IP-Adressen
          und Standortdaten der Nutzer gehören; Dienstanbieter: Google Cloud
          EMEA Limited, 70 Sir John Rogerson’s Quay, Dublin 2, Irland; {"\n"}
          Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO);{"\n"}
          Website: {" "}<a href="https://mapsplatform.google.com/">https://mapsplatform.google.com/;</a> {"\n"}
          Datenschutzerklärung: {" "}<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>. {"\n"}
          Grundlage Drittlandtransfers: Data Privacy Framework (DPF). {"\n"}
          • Instagram-Plugins und -Inhalte:
          Instagram Plugins und -Inhalte - Hierzu können z. B. Inhalte wie
          Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer
          Inhalte dieses Onlineangebotes innerhalb von Instagram teilen können.
          - Wir sind gemeinsam mit Meta Platforms Ireland Limited für die
          Erhebung oder den Erhalt im Rahmen einer Übermittlung (jedoch nicht
          die weitere Verarbeitung) von "Event-Daten", die Facebook mittels
          Funktionen von Instagram (z. B. Einbettungsfunktionen für Inhalte),
          die auf unserem Onlineangebot ausgeführt werden, erhebt oder im Rahmen
          einer Übermittlung zu folgenden Zwecken erhält, gemeinsam
          verantwortlich: {"\n"}
          a) Anzeige von Inhalten sowie Werbeinformationen, die
          den mutmaßlichen Interessen der Nutzer entsprechen; {"\n"}
          b) Zustellung
          kommerzieller und transaktionsbezogener Nachrichten (z. B. Ansprache
          von Nutzern via Facebook-Messenger); {"\n"}
          c) Verbesserung der
          Anzeigenauslieferung und Personalisierung von Funktionen und Inhalten
          (z. B. Verbesserung der Erkennung, welche Inhalte oder
          Werbeinformationen mutmaßlich den Interessen der Nutzer entsprechen).
          Wir haben mit Facebook eine spezielle Vereinbarung abgeschlossen
          ("Zusatz für Verantwortliche",
          {""}<a href="https://www.facebook.com/legal/controller_addendum">https://www.facebook.com/legal/controller_addendum</a>), in der
          insbesondere geregelt wird, welche Sicherheitsmaßnahmen Facebook
          beachten muss
          ({""}<a href="https://www.facebook.com/legal/terms/data_security_terms">https://www.facebook.com/legal/terms/data_security_terms</a>) und in der
          Facebook sich bereit erklärt hat die Betroffenenrechte zu erfüllen (d.
          h. Nutzer können z. B. Auskünfte oder Löschungsanfragen direkt an
          Facebook richten). Hinweis: Wenn Facebook uns Messwerte, Analysen und
          Berichte bereitstellt (die aggregiert sind, d. h. keine Angaben zu
          einzelnen Nutzern erhalten und für uns anonym sind), dann erfolgt
          diese Verarbeitung nicht im Rahmen der gemeinsamen Verantwortlichkeit,
          sondern auf Grundlage eines Auftragsverarbeitungsvertrages
          ("Datenverarbeitungsbedingungen ",
          {""}<a href="https://www.facebook.com/legal/terms/dataprocessing">https://www.facebook.com/legal/terms/dataprocessing</a>) , der
          "Datensicherheitsbedingungen"
          ({""}<a href="https://www.facebook.com/legal/terms/data_security_terms">https://www.facebook.com/legal/terms/data_security_terms</a>) sowie im
          Hinblick auf die Verarbeitung in den USA auf Grundlage von
          Standardvertragsklauseln ("Facebook-EU-Datenübermittlungszusatz,
          {""}<a href="https://www.facebook.com/legal/EU_data_transfer_addendum">https://www.facebook.com/legal/EU_data_transfer_addendum</a>). Die Rechte
          der Nutzer (insbesondere auf Auskunft, Löschung, Widerspruch und
          Beschwerde bei zuständiger Aufsichtsbehörde), werden durch die
          Vereinbarungen mit Facebook nicht eingeschränkt; {"\n"}
          Dienstanbieter: Meta Platforms Ireland Limited, Merrion Road, Dublin 4, D04 X2K5, Irland;{"\n"}
          Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); {"\n"}
          Website: {""}<a href="https://www.instagram.com">https://www.instagram.com</a>. {"\n"}
          Datenschutzerklärung:{""}<a href="https://instagram.com/about/legal/privacy/">https://instagram.com/about/legal/privacy/</a>. {"\n"}
        </p>

        <h2>Begriffsdefinitionen </h2>
        <p>
          In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
          Datenschutzerklärung verwendeten Begrifflichkeiten. Soweit die
          Begrifflichkeiten gesetzlich definiert sind, gelten deren gesetzliche
          Definitionen. Die nachfolgenden Erläuterungen sollen dagegen vor allem
          dem Verständnis dienen.{"\n"}
        </p>
        <p>
          • Personenbezogene Daten: "Personenbezogene Daten" sind alle
          Informationen, die sich auf eine identifizierte oder identifizierbare
          natürliche Person (im Folgenden "betroffene Person") beziehen; als
          identifizierbar wird eine natürliche Person angesehen, die direkt oder
          indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem
          Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
          (z. B. Cookie) oder zu einem oder mehreren besonderen Merkmalen
          identifiziert werden kann, die Ausdruck der physischen,
          physiologischen, genetischen, psychischen, wirtschaftlichen,
          kulturellen oder sozialen Identität dieser natürlichen Person sind.{" "}
          {"\n"}• Profile mit nutzerbezogenen Informationen: Die Verarbeitung
          von "Profilen mit nutzerbezogenen Informationen", bzw. kurz "Profilen"
          umfasst jede Art der automatisierten Verarbeitung personenbezogener
          Daten, die darin besteht, dass diese personenbezogenen Daten verwendet
          werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
          Person beziehen (je nach Art der Profilbildung können dazu
          unterschiedliche Informationen betreffend die Demographie, Verhalten
          und Interessen, wie z. B. die Interaktion mit Webseiten und deren
          Inhalten, etc.) zu analysieren, zu bewerten oder, um sie vorherzusagen
          (z. B. die Interessen an bestimmten Inhalten oder Produkten, das
          Klickverhalten auf einer Webseite oder den Aufenthaltsort). Zu Zwecken
          des Profilings werden häufig Cookies und Web-Beacons eingesetzt.{" "}
          {"\n"}• Reichweitenmessung: Die Reichweitenmessung (auch als Web
          Analytics bezeichnet) dient der Auswertung der Besucherströme eines
          Onlineangebotes und kann das Verhalten oder Interessen der Besucher an
          bestimmten Informationen, wie z. B. Inhalten von Webseiten, umfassen.
          Mit Hilfe der Reichweitenanalyse können Betreiber von Onlineangeboten
          z. B. erkennen, zu welcher Zeit Nutzer ihre Webseiten besuchen und für
          welche Inhalte sie sich interessieren. Dadurch können sie z. B. die
          Inhalte der Webseiten besser an die Bedürfnisse ihrer Besucher
          anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme
          Cookies und Web-Beacons eingesetzt, um wiederkehrende Besucher zu
          erkennen und so genauere Analysen zur Nutzung eines Onlineangebotes zu
          erhalten. {"\n"}• Standortdaten: Standortdaten entstehen, wenn sich
          ein mobiles Gerät (oder ein anderes Gerät mit den technischen
          Voraussetzungen einer Standortbestimmung) mit einer Funkzelle, einem
          WLAN oder ähnlichen technischen Mitteln und Funktionen der
          Standortbestimmung, verbindet. Standortdaten dienen der Angabe, an
          welcher geografisch bestimmbaren Position der Erde sich das jeweilige
          Gerät befindet. Standortdaten können z. B. eingesetzt werden, um
          Kartenfunktionen oder andere von einem Ort abhängige Informationen
          darzustellen. {"\n"}• Verantwortlicher: Als "Verantwortlicher" wird
          die natürliche oder juristische Person, Behörde, Einrichtung oder
          andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke
          und Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
          bezeichnet. {"\n"}• Verarbeitung: "Verarbeitung" ist jeder mit oder
          ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
          solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der
          Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten, sei
          es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das
          Löschen.{"\n"}
        </p>
        <p>
          Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
          Schwenke{"\n"}
        </p>
      </Text>
    </Box>
  );
};

export default Datenschutz;
