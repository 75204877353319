import React from "react";
import { styles} from "../styles";
import "../index.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Linkleiste from "../components/LinkLeiste.js";
import Logo from "../components/Logo.js";

const Galerie = () => {
  const isScreenSmall = useMediaQuery("(max-width:750px) and (min-width: 0px)");

  const [value, setValue] = React.useState("1");
  const minWindoSize = 500;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <HelmetProvider>
    <div>
        
    <Helmet>
      <title>Bilder der Anbauvereinigung Hemp Heaven Hannover</title>
      <meta
        name="description"
        content="Schau dir an wie es bei uns aussieht, welche Fortschritte wir machen und was es sonst noch neues gibt."
      />
      <meta
        name="keywords"
        content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
      />
    </Helmet>
      <Box
        component={Paper}
        sx={{
          maxWidth: 800,
          minWidth: 300,
          marginTop: 5,
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "center",
          padding: "2%",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Logo/>

        
        <p style={styles.boldText}>Hier findest du zukünftig Bilder vom Verein</p>
              </Box>
    </div>
</HelmetProvider>
  );
};

export default Galerie
