

import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#8ec68d",
      color: theme.palette.common,
      border: "1px solid black",
      "@media screen and (max-width: 900px)": {
        // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
        fontSize: 10, // Beispiel: Verkleinern Sie die Schriftgröße
        padding: "6px",
        minWidth: "25px",
        maxWidth: "100px",
        // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      "@media screen and (max-width: 900px)": {
        // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
        fontSize: 10, // Beispiel: Verkleinern Sie die Schriftgröße
        padding: "6px",
        minWidth: "25px",
        maxWidth: "100px",
        // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
      },
    },
    // Hinzufügen von Padding für das Eingabefeld
    position: "relative",
    padding: "8px",
    "@media screen and (max-width: 900px)": {
      // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
      padding: "6px",
      // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
    },
  }));

const SortableTableHeaderCell = ({ label, field, sortConfig, handleSort }) => {
  const isSorted = sortConfig.key === field;
  
  const isAscending = sortConfig.direction === 'ascending';

  return (
    <StyledTableCell
      align="left"
      onClick={() => handleSort(field)}
      sx={{
        position: 'relative',
        paddingRight: '30px', // Platz für das Dreieck
        "@media screen and (max-width: 900px)": {
          // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
          marginTop: '10px',
          // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
        },
      }}
    >
      {label}
      {isSorted && (
        // Pfeil als Pseudo-Element hinzufügen
        <span
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '0',
          }}
        >
          {isAscending ? (
            <ChevronRightIcon sx={{padding: '5', transform: "rotate(270deg)"}}/>
          ) : (
            <ChevronRightIcon sx={{transform: "rotate(90deg)"}}/>
          )}
        </span>
      )}
    </StyledTableCell>
  );
};

export default SortableTableHeaderCell;