import * as React from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import supabase from "../../../config/supabaseClient.js";
import { useState, useEffect } from "react";

export default function EditSurveyDialog() {
  const [latestSurvey, setLatestSurvey] = useState("");
  const [editedSurvey, setEditedSurvey] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    //console.log("Dialog wird geöffnet");
    setDialogOpen(true);
    //console.log(dialogOpen);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const fetchLatestSurvey = async () => {
      try {
        // Die Daten aus der "News"-Tabelle abrufen
        const { data, error } = await supabase
          .from("misc")
          .select("content")
          .eq("keyword", "News")
          .order("created_at", { ascending: false })
          .limit(1);

        if (error) {
          console.error("Fehler beim Abrufen des letzten Eintrags:", error);
          return;
        }

        // Den Text der letzten Nachricht setzen
        if (data && data.length > 0) {
            setLatestSurvey(data[0].message);
            setEditedSurvey(data[0].message); // Setze den bearbeiteten Text beim Laden der Nachricht
          } else {
            // Wenn keine Daten vorhanden sind, setzen Sie latestMessage auf einen Standardwert
            setLatestSurvey("Es gibt keine Nachrichten.");
          }
        } catch (error) {
          console.error("Allgemeiner Fehler:", error);
        }
      };

    // Die Funktion zum Abrufen der neuesten Nachricht aufrufen
    fetchLatestSurvey();
  }, []);

  const handleEditSurvey = async () => {
    try {
      // Die neuesten Daten aus der "News"-Tabelle abrufen
      const { data: latestData, error: latestError } = await supabase
        .from("misc")
        .select("content")
        .eq("keyword", "News")
        .order("created_at", { ascending: false })
        .limit(1);

       
  
      if (latestError) {
        console.error("Fehler beim Abrufen des neuesten Eintrags:", latestError);
        return;
      }
  
      
      if (latestData && latestData.length > 0) {
        // Die ID des neuesten Eintrags extrahieren
        const latestEntryId = latestData[0].id;
        
  
        // Den neuesten Eintrag in der "News"-Tabelle aktualisieren
        const { data: updatedData, error: updateError } = await supabase
          .from("misc")
          .update({ message: editedSurvey })
          .eq("id", latestEntryId);
  
        if (updateError) {
          console.error("Fehler beim Aktualisieren der Nachricht:", updateError);
          return;
        }
  
        // Erfolgreich aktualisierte Nachricht
        console.log("Nachricht erfolgreich aktualisiert:", updatedData);
        console.log(latestData)
        console.log(latestEntryId)
      } else {
        console.error("Keine Daten gefunden, um die Nachricht zu bearbeiten.");
      }
    } catch (error) {
      console.error("Allgemeiner Fehler:", error);
    }
    
    handleCloseDialog()
    //window.location.reload(false);
  };

  
  const handleDeleteSurvey = async () => {
    // Bestätigungsdialog anzeigen
    const confirmDelete = window.confirm("Möchten Sie die Nachricht endgültig löschen?");

    if (confirmDelete) {
      try {
        // Den letzten Eintrag in der "News"-Tabelle abrufen
        const { data, error } = await supabase
          .from("misc")
          .select("content")
          .eq("keyword", "News")
          .order("created_at", { ascending: false })
          .limit(1);

        if (error) {
          console.error("Fehler beim Abrufen des letzten Eintrags:", error);
          return;
        }

        if (data && data.length > 0) {
          // Die ID des neuesten Eintrags extrahieren
          const latestEntryId = data[0].id;

          // Den neuesten Eintrag in der "News"-Tabelle löschen
          const { error: deleteError } = await supabase
            .from("misc")
            .delete()
            .eq("id", latestEntryId);

          if (deleteError) {
            console.error("Fehler beim Löschen der Nachricht:", deleteError);
            return;
          }

          // Erfolgreich gelöschte Nachricht
          console.log("Nachricht erfolgreich gelöscht");

          // Optional: Setze den React Hook setLatestMessage, um den angezeigten Text zurückzusetzen
          setLatestSurvey("");
        } else {
          console.error("Keine Daten gefunden, um die Nachricht zu löschen.");
        }
      } catch (error) {
        console.error("Allgemeiner Fehler:", error);
      }
    }
    handleCloseDialog()
    window.location.reload(false);
  };

  return (
    <React.Fragment>
      <Button className="button" variant="contained" onClick={handleClickOpen}
        sx={{
          "@media screen and (max-width: 600px)": {
            // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
            fontSize: 10,
            // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
          },
        }} disabled >
        letzte Umfrage bearbeiten
      </Button>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle> Umfrage:</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              multiline
              rows={3}
              value={editedSurvey}
              onChange={(e) => {
                setEditedSurvey(e.target.value);
                /* console.log("Message State:", message) */
              }}
              style={{ width: "400px" }}
            ></TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Schließen</Button>
          <Button onClick={handleEditSurvey}>Ändern</Button>
          <Button onClick={handleDeleteSurvey}>Löschen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
