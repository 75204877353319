import React from "react";
import Button from "@mui/material/Button";
import supabase from "../../../config/supabaseClient";
import * as XLSX from "xlsx";

const GenerateMemberFeeList = () => {
  const generateMemberFeeList = async () => {
    try {
      // Daten aus Supabase abrufen
      const { data, error } = await supabase
        .from("User")
        .select(
          "lastname, firstname, mandat_reference, demand_next_month, special_contribution, model, role"
        );

      if (error) {
        console.error(
          "Fehler beim Abrufen der Daten aus Supabase:",
          error.message
        );
        return;
      }

      // Filtern der Daten um nur registrierte Benutzer aufzunehmen
      const filteredData = data.filter((row) => row.role !== "Registriert");

      // Excel-Datei erstellen
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet([
        [
          "Nachname",
          "Vorname",
          "Mitgliedsmodel",
          "Vereinsrolle",
          "Mandatsreferenz",
          "neuer Mitgliedsbeitrag",
        ],
        ...filteredData.map((row) => {
          let newFee;
          if (row.model === "Ehrenmitglied-Vereinspflege") {
            newFee = (
              parseFloat(row.demand_next_month) * 6 +
              parseFloat(row.special_contribution) * 8
            ).toFixed(2);
          } else if (row.model === "Ehrenmitglied-Privatpflege") {
            newFee = (parseFloat(row.special_contribution) * 8).toFixed(2);
          } else {
            newFee =
              row.role === "Prime-Mitglied"
                ? (
                    parseFloat(row.demand_next_month) * 6 +
                    parseFloat(row.special_contribution) * 8 +
                    25
                  ).toFixed(2)
                : (
                    parseFloat(row.demand_next_month) * 7 +
                    parseFloat(row.special_contribution) * 9 +
                    25
                  ).toFixed(2);
          }
          return [
            row.lastname,
            row.firstname,
            row.model,
            row.role,
            row.mandat_reference,
            newFee + " €",
          ];
        }),
      ]);

      // Excel-Datei herunterladen
      const currentDate = new Date()
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "_");
      const fileName = `${currentDate}_Mitgliedsbeiträge.xlsx`;
      XLSX.utils.book_append_sheet(workbook, worksheet, "Mitgliedsbeiträge");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Ein Fehler ist aufgetreten:", error.message);
    }
  };

  return (
    <Button
      className="button"
      variant="contained"
      onClick={generateMemberFeeList}
      sx={{
        "@media screen and (max-width: 600px)": {
          fontSize: 10, // Stil für kleinere Bildschirme
        },
      }}
    >
      Beitragsliste erstellen
    </Button>
  );
};

export default GenerateMemberFeeList;
