import React from "react";

const Logo = () => {
  return (
<div style={{ display: "flex", alignItems: "center" }}>
  
  <div style={{ maxWidth: "200px", maxHeight: "200px", width: "100%", display: "flex", justifyContent: "center" }}>
    <img
      src={require(`../images/Background/HHH-ev-200px.webp`)}
      alt="Logo der Anbauvereinigung Hemp Heaven Hannover"
      style={{ maxWidth: "100%", maxHeight: "100%" }}
    />
  </div>
</div>



  );
};

export default Logo;
