import React from "react";
import { styles } from "../styles";
import "../index.css";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Logo from "../components/Logo";

const Sitemap = () => {
  return (
    <div>
      <Box
        component={Paper}
        sx={{
          maxWidth: 800,
          minWidth: 300,
          marginTop: 5,
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "center",
          padding: "2%",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Logo />

        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Startseite" href="/" style={styles.normalText}>
            Startseite
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Anmeldung" href="/login" style={styles.normalText}>
            Anmeldung
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Registrierung" href="/signup" style={styles.normalText}>
            Registrierung
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Beiträge" href="/Beitraege" style={styles.normalText}>
            Beiträge
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Vereinskodex" href="/Vereinskodex" style={styles.normalText}>
          Vereinskodex
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Vereinsdokumente" href="/Vereinsdokumente" style={styles.normalText}>
          Vereinsdokumente
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Gesundheit & Prävention" href="/Gesundheit" style={styles.normalText}>
          Gesundheit & Prävention
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Mitglied werden" href="/Mitgliedwerden" style={styles.normalText}>
          Mitglied werden
          </a> 
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Galerie" href="/Galerie" style={styles.normalText}>
          Galerie
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Pflanzen-Tabelle" href="/plantsdata" style={styles.normalText}>
            Pflanzen-Tabelle
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Ausrüstung" href="/equipment" style={styles.normalText}>
            Ausrüstung
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Impressum" href="/impressum" style={styles.normalText}>
            Impressum
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Datenschutz" href="/datenschutz" style={styles.normalText}>
            Datenschutz
          </a>
        </p>
        <p style={{ ...styles.normalText, margin: "5px" }}>
          <a title="Sitemap" href="/sitemap" style={styles.normalText}>
            Sitemap
          </a>
        </p>
      </Box>
    </div>
  );
};

export default Sitemap;

