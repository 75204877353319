//Basic
import { React, useEffect, useState } from "react";
import { Text } from "react-native";
import "../../App.css";
import supabase from "../../config/supabaseClient";
import { Fragment } from "react";
import { styles } from "../../styles";



//comps
import MemberTable from './components/MemberTable'
import CreateMemberMessageDialog from "./components/CreateMemberMessageDialog";
import EditMemberMessageDialog from "./components/EditMemberMessageDialog";
import CreateSurveyDialog from "./components/CreateSurveyDialog";
import EditSurveyDialog from "./components/EditSurveyDialog";
import GenerateMemberFeeList from "./components/GenerateMemberFeeList";

//MUI
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";

const MemberManagement = (props) => {
    const { setToken, token, UserData, setUserData } = props;

    const [registeredcount, setRegCount] = useState(null);
    const [membercount, setMemCount] = useState(null);
  
    const [demandcount, setDemCount] = useState(null);
    const [nextdemandcount, setNextDemCount] = useState(null);
    
  const [members, setMembers] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  
  const [orderBy, setOrderBy] = useState("created_at");
  const [ascending, setAscending] = useState(true);

  const isScreenSmall = useMediaQuery("(max-width:750px)");

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const { data, error } = await supabase.from("User").select("*");

        if (error) {
          setFetchError("Could not fetch the Members");
          setMembers(null);
          console.error(error);
          return;
        }

        if (data) {
          const sortedData = data.sort((a, b) => {
            const roleA = a.role || "";
            const roleB = b.role || "";
            return ascending
              ? roleA.localeCompare(roleB)
              : roleB.localeCompare(roleA);
          });

          setMembers(sortedData);
          setFetchError(null);
          // console.log(sortedData);
        }
      } catch (error) {
        console.error("Error fetching Members:", error);
        setFetchError("Could not fetch the Members");
      }
    };

    fetchMembers();
    //console.log(members);
  }, [orderBy, ascending]);


    useEffect(() => {
        const fetchCounts = async () => {
          try {
            const { data, error } = await supabase.from("User").select("*");
    
            if (error) {
              setFetchError("Error fetching data");
              setRegCount(null);
              setMemCount(null);
              console.error(error);
              return;
            }
    
            if (data && data.length > 0) {
              // Zähle registrierte Benutzer
              const registeredCount = data.filter(
                (entry) => entry.role === "Registriert"
              ).length;
    
              // Zähle Mitglieder (nicht registriert)
              const memberCount = data.filter(
                (entry) => entry.role !== "Registriert" && entry.role !== "Bann"
              ).length;
    
              // Summiere die Werte der Spalte 'current_demand'
              const currentDemandSum = data.reduce(
                (sum, entry) =>
                  sum + (entry.current_demand ? parseInt(entry.current_demand) : 0),
                0
              );
    
              // Summiere die Werte der Spalte 'demand_next_month'
              const nextDemandSum = data.reduce(
                (sum, entry) =>
                  sum +
                  (entry.demand_next_month ? parseInt(entry.demand_next_month) : 0),
                0
              );
    
              setDemCount(currentDemandSum);
              setNextDemCount(nextDemandSum);
              setRegCount(registeredCount);
              setMemCount(memberCount);
              setFetchError(null);
              //console.log(orderBy)
            } else {
              setRegCount(0);
              setMemCount(0);
              setFetchError("No data found");
            }
          } catch (error) {
            console.error("General error:", error);
          }
        };
    
        fetchCounts();
      }, [orderBy]);


  return (
    <>
      {UserData[0].role === "Admin" || UserData[0].role === "Vorstand" ? (
        <div>
          {isScreenSmall ? (
            <div
              style={{ display: "flex", justifyContent: "center", margin: 5 }}
            >
              {fetchError && <p>{fetchError}</p>}
              {members && members.length > 0 && (
              <Grid container="true" spacing={1} marginTop={1}>
                <Grid item="true" xs={6} className="member-count">
                  <Box
                    component={Paper}
                    sx={{
                      maxWidth: 400,
                      minHeight: 120,
                      padding: 2,
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                    }}
                  >
                    <Text> Angemeldet: {registeredcount}</Text>
                    <Text> Mitglieder: {membercount}</Text>
                  </Box>
                </Grid>

                <Grid item="true" xs={6} className="demand-count"> 
                  <Box
                    component={Paper}
                    sx={{
                      maxWidth: 400,
                      minHeight: 120,
                      padding: 2,
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                    }}
                  >
                    <Text>
                      {" "}
                      Bedarfsmenge diesen Monat:{"\n"} {demandcount} g
                    </Text>
                    <Text>
                      {" "}
                      Bedarfsmenge nächsten Monat:{"\n"} {nextdemandcount} g
                    </Text>
                  </Box>
                </Grid>

                <Grid item="true" xs={6} className="sort-box">
                  <Box
                    component={Paper}
                    sx={{
                      maxWidth: 400,
                      minHeight: 190,
                      padding: 2,
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text>Sortieren nach:</Text>
                    <Button
                      onClick={() => {
                        setOrderBy("created_at");
                        setAscending((prevAscending) => !prevAscending);
                        //console.log(ascending);
                      }}
                      className="button"
                      variant="contained"
                      size="small"
                      sx={{
                        width: "100%",
                        marginBottom: 1,
                        "@media screen and (max-width: 600px)": {
                          // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                          fontSize: 10,
                          // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
                        },
                      }}
                    >
                      Registriert am
                    </Button>
                    <Button
                      sx={{
                        width: "100%",
                        marginBottom: 1,
                        "@media screen and (max-width: 600px)": {
                          // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                          fontSize: 10,
                          // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
                        },
                      }}
                      onClick={() => {
                        setOrderBy("role"); // Beachte die Änderung hier
                        setAscending((prevAscending) => !prevAscending);
                      }}
                      className="button"
                      variant="contained"
                      size="small"
                    >
                      Status
                    </Button>
                    <Text>
                      {" "}
                      {`Sortieren nach ${orderBy === 'created_at' ? 'Zeitpunkt der Registrierung' : 'Status'} (${ascending ? 'aufsteigend' : 'absteigend'})`}
                    </Text>
                  </Box>
                </Grid>

                <Grid item="true" xs={6} className="message-box">
                  <Box
                    component={Paper}
                    sx={{
                      maxWidth: 400,
                      minHeight: 190,
                      padding: 2,
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Vereinsmitteilung erstellen:</Text>
                    <CreateMemberMessageDialog></CreateMemberMessageDialog>
                    <EditMemberMessageDialog></EditMemberMessageDialog>
                  </Box>
                </Grid>
                <Grid item="true" xs={6} className="survey-box">
                  <Box
                    component={Paper}
                    sx={{
                      maxWidth: 400,
                      minHeight: 160,
                      padding: 2,
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Umfrage erstellen:</Text>
                    <CreateSurveyDialog></CreateSurveyDialog>
                    <EditSurveyDialog></EditSurveyDialog>
                  </Box>
                </Grid>

                <Grid item="true" xs={6} className="fee-list">
                  <Box
                    component={Paper}
                    sx={{
                      maxWidth: 400,
                      minHeight: 160,
                      padding: 2,
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                    }}
                  >
                    <GenerateMemberFeeList></GenerateMemberFeeList>
                  </Box>
                </Grid>

                <Grid item="true" xs={12}>
                  <Fragment>
                  <MemberTable setToken={setToken}
                    token={token}
                    UserData={UserData}
                    setUserData={setUserData}
                    order={orderBy}
                    ascending={ascending}></MemberTable>
                  </Fragment>
                </Grid>
              </Grid>)}
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {fetchError && <p>{fetchError}</p>}
              {members && members.length > 0 && (
                <Grid container="true" my={5} mx={0}>
                  <Grid item="true" xs={4}>
                    <Stack spacing={2}>
                      <Box
                        component={Paper}
                        sx={{
                          maxWidth: 200,
                          marginTop: 5,
                          padding: 2,
                          display: "flex",
                          alignItems: "left",
                          flexDirection: "column",
                        }}
                      >
                        <Text> Angemeldet: {registeredcount}</Text>
                        <Text> Mitglieder: {membercount}</Text>
                      </Box>

                      <Box
                        component={Paper}
                        sx={{
                          maxWidth: 200,
                          marginTop: 5,
                          padding: 2,
                          display: "flex",
                          alignItems: "left",
                          flexDirection: "column",
                        }}
                      >
                        <Text>
                          {" "}
                          Bedarfsmenge diesen Monat:{"\n"} {demandcount} g
                        </Text>
                        <Text>
                          {" "}
                          Bedarfsmenge nächsten Monat:{"\n"} {nextdemandcount} g
                        </Text>
                      </Box>

                      <Box
                        component={Paper}
                        sx={{
                          maxWidth: 200,
                          marginTop: 5,
                          padding: 2,
                          display: "flex",
                          alignItems: "left",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Text>Sortieren nach:</Text>
                        <Button
                          onClick={() => {
                            setOrderBy("created_at");
                            ascending === true
                              ? setAscending(false)
                              : setAscending(true);
                            //console.log(ascending);
                          }}
                          className="button"
                          variant="contained"
                          size="small"
                          sx={{
                            width: "100%",
                            marginBottom: 1,
                            "@media screen and (max-width: 600px)": {
                              // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                              fontSize: 10,
                              // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
                            },
                          }}
                        >
                          Registriert am
                        </Button>
                        <Button
                          sx={{
                            width: "100%",
                            marginBottom: 1,
                            "@media screen and (max-width: 600px)": {
                              // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                              fontSize: 10,
                              // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
                            },
                          }}
                          onClick={() => {
                            setOrderBy("role"); // Beachte die Änderung hier
                            setAscending((prevAscending) => !prevAscending);
                          }}
                          className="button"
                          variant="contained"
                          size="small"
                        >
                          Status
                        </Button>
                        <Text>
                          {" "}
                          {" "}
                      {`Sortieren nach ${orderBy === 'created_at' ? 'Zeitpunkt der Registrierung' : 'Status'} (${ascending ? 'aufsteigend' : 'absteigend'})`}
                        </Text>
                      </Box>
                      <Box
                        component={Paper}
                        sx={{
                          maxWidth: 200,
                          marginTop: 5,
                          padding: 2,
                          display: "flex",
                          alignItems: "left",
                          flexDirection: "column",
                        }}
                      >
                        <Text>Vereinsmitteilung erstellen:</Text>
                        <CreateMemberMessageDialog></CreateMemberMessageDialog>
                        <EditMemberMessageDialog></EditMemberMessageDialog>
                      </Box>
                      <Box
                        component={Paper}
                        sx={{
                          maxWidth: 200,
                          marginTop: 5,
                          padding: 2,
                          display: "flex",
                          alignItems: "left",
                          flexDirection: "column",
                        }}
                      >
                        <Text>Umfrage erstellen:</Text>
                        <CreateSurveyDialog></CreateSurveyDialog>
                        <EditSurveyDialog></EditSurveyDialog>
                      </Box>
                      <Box
                        component={Paper}
                        sx={{
                          maxWidth: 200,
                          marginTop: 5,
                          padding: 2,
                          display: "flex",
                          alignItems: "left",
                          flexDirection: "column",
                        }}
                      >
                        <GenerateMemberFeeList></GenerateMemberFeeList>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item="true" xs={8}>
                    <Fragment>
                    <MemberTable setToken={setToken}
                    token={token}
                    UserData={UserData}
                    setUserData={setUserData}
                    order={orderBy}
                    ascending={ascending}></MemberTable>
                    </Fragment>
                  </Grid>
                </Grid>
              )}
            </div>
          )}
        </div>
      ) : (
        <Box sx={{ margin: "auto", marginTop: 5, textAlign: "center" }}>
        <Text style={styles.titleTextWhite}>
          Für diesen Teil der Website hast du keine Berechtigung!
        </Text>
        </Box>
      )}
    </>
  )
}

export default MemberManagement
