import React from "react";
import { styles, smallScreenStyles } from "../styles";
import "../index.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Linkleiste from "../components/LinkLeiste.js";
import Logo from "../components/Logo.js";

const Vereinskodex = () => {
  const isScreenSmall = useMediaQuery("(max-width:750px) and (min-width: 0px)");

  const [value, setValue] = React.useState("1");
  const minWindoSize = 500;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <HelmetProvider>
    <div className="page-home">
    <Helmet>
      <title>
        Der Kodex der Anbauvereinigung Hemp Heaven Hannover
      </title>
      <meta
        name="description"
        content="Informiere dich darüber welche Werte die Anbauvereinigung Hemp Heaven Hannover vertritt und wofür wir stehen."
      />
      <meta
        name="keywords"
        content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
      />
    </Helmet>
      <Box
        component={Paper}
        sx={{
          maxWidth: 800,
          minWidth: 300,
          marginTop: 5,
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "center",
          padding: "2%",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Logo/>

        {/* <Text style={styles.titleText}>Hanffarm AV Hannover {"\n"}</Text> */}
        {/*                 <div style={{ textAlign: "center" }}>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        Momentan nehmen wir keine neuen Mitglieder auf. Ihr könnt
                        euch dennoch auf dieser Website registrieren und werdet
                        dadurch auf eine Warteliste aufgenommen.
                      </span>
                    </div>
                    {"\n"}
                    {"\n"} */}
        <h1
          style={{
            whiteSpace: "pre-line",
            fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
          }}
        >
          Vereinskodex
        </h1>
        <p style={styles.normalText}>
          Die Anbauvereinigung Hemp Heaven Hannover steht grundsätzlich für jeden offen, der das gesetzlich
          vorgegebene Alter erreicht hat. Jeder wird toleriert und akzeptiert.
          Akzeptanz findet dort seine Grenze, wo andere zu Schaden kommen oder
          Gesetze überschritten werden. Dies gilt explizit für
          verantwortungsloses Konsumverhalten - finanzieller oder psychischer
          Natur, die Weitergabe von Cannabis an Nichtmitglieder, Konsum in der
          Nähe von Minderjährigen oder
          Suchterkrankten/ehemaligen Suchterkrankten, Diskriminierung von Personen aufgrund
          ihrer Abstammung, physischer Attribute, Bildungsgrad, Intellekts oder
          ihres Glaubens.
        </p>
      </Box>
    </div>
</HelmetProvider>
  );
};

export default Vereinskodex;
