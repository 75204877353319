import React from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import "../App.css";

import { styles } from "../styles";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import groups from "../images/Icons/groups.svg";
import home from "../images/Icons/home.svg";
import monitoring from "../images/Icons/monitoring.svg";
import person from "../images/Icons/person.svg";
import yard from "../images/Icons/yard.svg";
import Instagram from "../images/Icons/icons8-instagram.svg";
import Linkleiste from "./LinkLeiste";
import Logo from "../images/Background/HHH-ev-40px.webp";

const NavBar = ({ UserData }) => {
  const navigate = useNavigate();

  function handleLogout(e) {
    e.preventDefault();
    supabase.auth.signOut();
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload(false);
  }

  const handleNavigation = (path) => {
    navigate(path);
  };

  function handleSignin(e) {
    e.preventDefault();
    navigate("/login");
  }

  return (
    <div style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 1000 }}>
      <nav style={{ height: "45px" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Box display="flex" alignItems="center">
            {UserData !== null && UserData.length > 0 ? (
              <Button
                type="submit"
                className="signout"
                color="success"
                variant="contained"
                onClick={handleLogout}
                sx={{ marginRight: "auto" }}
              >
                Abmelden
              </Button>
            ) : (
              <Button
                type="submit"
                className="signin"
                variant="contained"
                color="success"
                onClick={handleSignin}
                sx={{ marginRight: "auto" }}
              >
                Anmelden
              </Button>
            )}
            {UserData !== null && UserData.length > 0 && (
              <>
                <img
                  src={person}
                  alt="Profil"
                  className="svg-icon"
                  onClick={() => handleNavigation("/profile")}
                />
                <img
                  src={monitoring}
                  alt="Umfragenergebnisse"
                  className="svg-icon"
                  onClick={() => handleNavigation("/statistics")}
                />
              </>
            )}

            {UserData !== null &&
              UserData.length > 0 &&
              (UserData[0].role === "Admin" ||
                UserData[0].role === "Vorstand") && (
                <>
                  <img
                    src={groups}
                    alt="Mitgliedermanagement"
                    className="svg-icon"
                    onClick={() => handleNavigation("/member-table")}
                  />
                </>
              )}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center"></Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ flexGrow: 1 }}
          >
            <img
              src={home}
              className="svg-icon"
              height={40}
              width={40}
              alt="Startseite"
              onClick={() => handleNavigation("/")}
              style={{ marginRight: 10 }}
            />
            <Linkleiste />
          </Box>
        </Box>
      </nav>
    </div>
  );
};

export default NavBar;
