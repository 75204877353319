import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({

  
  titleText: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#333", // Beispiel für Textfarbe (#333 entspricht Dunkelgrau)
  },
  titleTextWhite: {
    fontSize: 30,
    fontWeight: "bold",
    color: "white", // Beispiel für Textfarbe (#333 entspricht Dunkelgrau)
  },
  subTitelText: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#333", // Beispiel für Textfarbe (#333 entspricht Dunkelgrau)
  },
  normalText: {
    fontSize: 16,
    fontWeight: "",
    color: "#333", // Beispiel für Textfarbe (#333 entspricht Dunkelgrau)
    width: '100%',
  },
  normalTextRed: {
    fontSize: 16,
    color: "red", // Beispiel für Textfarbe (#333 entspricht Dunkelgrau)
    width: '100%',
  },
  boldTextRed: {
    fontSize: 16,
    fontWeight: "bold",
    color: "red", // Beispiel für Textfarbe (#333 entspricht Dunkelgrau)
    width: '100%',
  },
  smallText: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#333", // Beispiel für Textfarbe (#333 entspricht Dunkelgrau)
    width: '100%',
  },
  smallTextRed: {
    fontSize: 12,
    color: "red", // Beispiel für Textfarbe (#333 entspricht Dunkelgrau)
    width: '100%',
  },
  normalTextWhite: {
    fontSize: 16,
    fontWeight: "",
    color: "White", // Beispiel für Textfarbe (#333 entspricht Dunkelgrau)
    width: '100%',
  },
  boldText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    width: '100%',
  },
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: '100%',
  },
  textContainer: {
    flex: 1,
    marginRight: 10, // Adjust the margin as needed
    width: '100%',
  },
  image: {
    maxWidth: 200,
    maxHeight: 300,
  },
  
});

// Füge die folgenden Stile für Bildschirmbreiten kleiner oder gleich 380px hinzu
const smallScreenStyles = StyleSheet.create({
  container: {
    flexDirection: 'column', // Ändere die Ausrichtung auf Spalten
    width: '100%',
  },
});


export { smallScreenStyles };

