import React from "react";
import { styles } from "../styles";
import "../index.css";
import Paper from "@mui/material/Paper";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Linkleiste from "../components/LinkLeiste.js";
import Logo from "../components/Logo.js";
import PriceModelCards from "../components/PriceModelCards.js";

const MitgliedWerden = () => {
  const [value, setValue] = React.useState("1");
  const minWindoSize = 500;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <HelmetProvider>
      <div className="page-home">
        <Helmet>
          <title>
            Wie wirst du Mitglied der Anbauvereinigung Hemp Heaven Hannover
          </title>
          <meta
            name="description"
            content="Informiere dich darüber wie du Mitglied in der Anbauvereinigung Hemp Heaven Hannover werden kannst, welche Voraussetzungen du erfüllen musst und wie das weitere Vorgehen ist."
          />
          <meta
            name="keywords"
            content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
          />
        </Helmet>
        <Box
          component={Paper}
          sx={{
            maxWidth: 800,
            minWidth: 300,
            marginTop: 5,
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Logo />

          {/* <Text style={styles.titleText}>Hanffarm AV Hannover {"\n"}</Text> */}
          {/*                 <div style={{ textAlign: "center" }}>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        Momentan nehmen wir keine neuen Mitglieder auf. Ihr könnt
                        euch dennoch auf dieser Website registrieren und werdet
                        dadurch auf eine Warteliste aufgenommen.
                      </span>
                    </div>
                    {"\n"}
                    {"\n"} */}
          <h1
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            So wirst du zu einem Mitglied
          </h1>
{/*           <p style={styles.boldTextRed}>
            Wer sich bis zum 23.07.2024 als Mitglied angemeldet hat, erhält ab der ersten Abgabe ein Jahr 1 € Rabatt auf jedes Gramm Cannabis!
          </p> */}
          <p style={styles.normalText}>
            Um Mitglied zu werden, musst du mindestens 18 Jahre alt sein, seit
            mindestens 6 Monaten in Deutschland wohnen und dich auf dieser
            Website unverbindlich registrieren und anschließend unten auf deinem
            Profil den Mitgliedsantrag herunterladen. Auf dem Mitgliedsantrag entscheidest du dich für eines der beiden Vereinsmodelle.
          </p>
          <a title="Registrier-Button" href="/signup">
            <Button
              className="signin"
              variant="contained"
              color="success"
              align-items="flex-start"
              sx={{
                marginRight: "auto",
                "@media screen and (max-width: 380px)": {
                  // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                  fontSize: 10,
                  // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
                },
              }}
            >
              Registrieren
            </Button>
          </a>

          <PriceModelCards />

          <p style={styles.normalText}>
            Wenn du den Mitgliedsantrag vollständig ausgefüllt und
            unterschrieben hast, kannst du den Antrag inklusive einer Kopie der
            Vorder- und Rückseite deines Ausweises an
            vorstand@hemp-heaven-hannover.de schicken. Nachdem der Vereinsvorstand
            über deine Aufnahme entschieden hat, wirst du per E-Mail darüber
            informiert. Falls du dich für das Modell der Vereinspflege
            entschieden hast, hast du ab dem Empfang der E-Mail eine Woche Zeit,
            die Erstausstattungsgebühr inklusive Aufnahmegebühr (520 €) an das
            Vereinskonto zu zahlen.
          </p>

          <p style={styles.normalText}>
            Ab dem Zeitpunkt deiner Mitgliedschaft erhältst du auf deinem Profil
            den Status "Mitglied" und kannst deine monatliche Konsummenge
            einstellen, an Umfragen teilnehmen und das Cannabis bewerten. Die
            Konsummenge (Bedarfs-Mitgliedsbeitrag, siehe Beitragsordnung) wird
            erst dann abgerechnet, wenn sie im Vereinsheim zur Verfügung steht.
            Du brauchst dir in den ersten Monaten des Vereinsbestehens daher
            keine Sorgen machen, dass du für etwas zahlst, das du nicht erhalten
            kannst. Wenn du deine Konsummenge einstellst, hilft uns das, den
            Konsumbedarf des gesamten Vereins einschätzen zu können. Mit dieser
            Information können wir abschätzen, ob neue Mitglieder zugelassen und
            damit die monatlichen Mitgliedsbeiträge verringert werden können.
            Die Beiträge werden immer am 15. eines Monats von deinem Konto als
            Lastschrift abgebucht.
          </p>

{/*           <p style={styles.boldTextRed}>
            Wer sich bis zum 23.07.2024 als Mitglied angemeldet hat, erhält ab der ersten Abgabe ein Jahr 1 € Rabatt auf jedes Gramm Cannabis!
          </p> */}


          <p style={styles.normalText}>
            Du hast Fragen rund um den Verein? Schreibe deine Frage an
            info@hemp-heaven-hannover.de
          </p>
        </Box>
      </div>
    </HelmetProvider>
  );
};

export default MitgliedWerden;
