import React from 'react'
import { styles } from "../styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2



const PriceModelCards = () => {const isScreenSmall = useMediaQuery("(max-width:600px) and (min-width: 0px)");

    const bull = (
        <Box component="span" sx={{ mx: "2px", transform: "scale(0.8)" }}>
          •
        </Box>
      );
    
      const card1 = (
        <React.Fragment>
          <CardContent>
            <h2 style={styles.subTitelText}>Vereinspflege-Modell</h2>
            <p style={{ ...styles.normalText, padding: 0 }}>
              {bull} monatlich Basis-Mitgliedsbeitrag: 25 €<br />
              {bull} monatlich Bedarfs-Mitgliedsbeitrag: 7 €/g
              <br />
              {bull} einmalig Aufnahmegebühr: 20 €<br />
              {bull} einmalig Erstausstattungsgebühr: 500 €<br />
              {bull} Ausstattung wird dir für ca. 300 € abgekauft.<br />
              {bull} Die Pflanzen werden für dich auf der Produktionsfläche angebaut und gepflegt.
              <br />
              {bull} Garantie auf den Erhalt von Cannabis in der angegebenen Bedarfsmenge.
              <br />
            </p>
          </CardContent>
        </React.Fragment>
      );
    
      const card2 = (
        <React.Fragment>
          <CardContent>
            <h2 style={styles.subTitelText}>Privatpflege-Modell</h2>
            <p style={{ ...styles.normalText, padding: 0}}>
              {bull} monatlich Basis-Mitgliedsbeitrag: 25 €<br />
              {bull} monatlich Sonderbeitrag: 9 €/g
              <br />
              {bull} einmalig Aufnahmegebühr: 20 €<br />
              {bull} Du musst deine Pflanzen bei dir Zuhause selbst anbauen und pflegen.
              <br />
            </p>
          </CardContent>
        </React.Fragment>
      );
  return (
    <div>
                <Box sx={{ minWidth: 275 }}>
            <Grid
            container="true"
              spacing={2}
              my={1}
              mx={1}
              sx={{ display: "flex", alignItems: "stretch" }}
            >
              <Grid item="true" xs={isScreenSmall ? 12 : 6} sx={{ display: "flex" }}>
                <Card
                  variant="outlined"
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {card1}
                </Card>
              </Grid>
              <Grid item="true" xs={isScreenSmall ? 12 : 6} sx={{ display: "flex" }}>
                <Card
                  variant="outlined"
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {card2}
                </Card>
              </Grid>
            </Grid>
          </Box>
    </div>
  )
}

export default PriceModelCards
