import React from "react";
import { Box } from "@mui/material";
import { Text } from "react-native-web";
import { styles } from "../../styles";
import Paper from "@mui/material/Paper";

const Impressum = () => {
  return (
    <div>
      <Box
        component={Paper}
          display="flex"
          alignItems="center"
          flexDirection="column"
          textAlign="left"
          width="75%"  // Anpassung: Die Box nimmt 60% der Breite ein
          maxWidth={430}
          margin="auto" // Anpassung: Die Box wird zentriert
          marginTop={5}
          padding={2}
        >
          <Text style={styles.titleText}>Impressum{"\n"}{"\n"}</Text>
          <Text>
            <span style={styles.boldText}>Hemp Heaven Hannover e.V.</span>{"\n"}
            Fössestraße 103 {"\n"}
            30453 Hannover {"\n"}
            {"\n"}
            <span style={styles.boldText}>Telefon: </span>+49 173 186 175 9{" "}
            {"\n"}
            <span style={styles.boldText}>E-Mail: </span>
            info@hemp-heaven-hannover.de {"\n"}
            {"\n"}

            <span style={styles.boldText}>
              Gemeinschaftlicher vertretungsberechtigter:{" "}
            </span>
            {"\n"}
            <span style={styles.boldText}>1. Vorsitzender: </span>
            {"\n"}
            Hannes Lokatis{"\n"}
            Fössestraße 103 {"\n"}
            30453 Hannover {"\n"}
            {"\n"}
            <span style={styles.boldText}>Telefon: </span>+49 173 186 175 9{" "}
            {"\n"}
            <span style={styles.boldText}>E-Mail: </span>
            vorstand@hemp-heaven-hannover.de{"\n"}
            {"\n"}

            <span style={styles.boldText}>Vereinsdaten: </span>
            {"\n"}
            <span style={styles.boldText}>Registergericht: </span>Amtsgericht
            Hannover-Nord{"\n"}
            <span style={styles.boldText}>Registernummer: </span>VR 203970{"\n"}
            <span style={styles.boldText}>
              Umsatzsteuer-Identifikationsnummer:
            </span>
            {"\n"}
            DE XXXXXX {"\n"}
            {"\n"}

            <span style={styles.boldText}>V.i.S.d § 18 Abs. 2 MStV: </span>
            {"\n"}
            Hannes Lokatis{"\n"}
            Fössestraße 103 {"\n"}
            30453 Hannover {"\n"}
          </Text>
        </Box>
{/*    Weiße Schrift ohne Hintergrund
<Box
        //component={Paper}
          display="flex"
          alignItems="center"
          flexDirection="column"
          textAlign="left"
          width="60%"  // Anpassung: Die Box nimmt 60% der Breite ein
          margin="auto" // Anpassung: Die Box wird zentriert
          marginTop={5}
          padding={2}
        >
          <Text style={styles.titleTextWhite}>Impressum{"\n"}{"\n"}</Text>
          <Text style={{ color: 'white' }}>
            <span style={styles.boldText}>Hanffarm AV Hannover e.V.</span>{"\n"}
            Fössestraße 103 {"\n"}
            30453 Hannover {"\n"}
            {"\n"}
            <span style={styles.boldText}>Telefon: </span>+49 173 186 175 9{" "}
            {"\n"}
            <span style={styles.boldText}>E-Mail: </span>
            info@HanffarmAV-Hannover.de {"\n"}
            {"\n"}
          </Text>

          <Text style={{ color: 'white' }}>
            <span style={styles.boldText}>
              Gemeinschaftlicher vertretungsberechtigter:{" "}
            </span>
            {"\n"}
            <span style={styles.boldText}>1. Vorsitzender: </span>
            {"\n"}
            Hannes Lokatis{"\n"}
            Fössestraße 103 {"\n"}
            30453 Hannover {"\n"}
            {"\n"}
            <span style={styles.boldText}>Telefon: </span>+49 173 186 175 9{" "}
            {"\n"}
            <span style={styles.boldText}>E-Mail: </span>
            Vorstand@HanffarmAV-Hannover.de {"\n"}
            {"\n"}
          </Text>

          <Text style={{ color: 'white' }}>
            <span style={styles.boldText}>Vereinsdaten: </span>
            {"\n"}
            <span style={styles.boldText}>Registergericht: </span>Amtsgericht
            Hannover-Nord{"\n"}
            <span style={styles.boldText}>Registernummer: </span>VR XXXXX{"\n"}
            <span style={styles.boldText}>
              Umsatzsteuer-Identifikationsnummer:
            </span>
            {"\n"}
            DE XXXXXX {"\n"}
            {"\n"}
          </Text>

          <Text style={{ color: 'white' }}>
            <span style={styles.boldText}>V.i.S.d § 18 Abs. 2 MStV: </span>
            {"\n"}
            Hannes Lokatis{"\n"}
            Fössestraße 103 {"\n"}
            30453 Hannover {"\n"}
          </Text>
        </Box> */}
    </div>
  );
};

export default Impressum;
