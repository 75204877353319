import React from 'react'
import { styles } from "../../../styles";
import { Text } from "react-native";
import { Box, IconButton, Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import map from '../../../images/Icons/map.svg'


const OpeningTimes = () => {
    const googleMapsLink = "https://maps.app.goo.gl/yWcMnW1ddwE4TTee8"; // Link zu Google Maps mit der Adresse
  
    const openGoogleMaps = () => {
      window.open(googleMapsLink, "_blank"); // Öffne den Link in einem neuen Tab
    };
  return (
    <Box component={Paper} padding={1}>
      <Text style={styles.titleText}>Adresse:{"\n"}</Text>
      <Grid container="true" mx={2}>
      <Grid item="true" xs={4} my={2}>
      <Text style={styles.normalText}>Fössestraße 103 {"\n"}
      30453 Hannover{"\n"}</Text></Grid>
      <Grid item="true" xs={8} my={2}>
        <IconButton onClick={openGoogleMaps}>
        <img src={map} alt="Google Maps" style={{ marginRight: "5px" }}/><Text style={styles.normalText}>Google Maps öffnen</Text></IconButton></Grid></Grid>
      <Text style={styles.titleText}>Öffnungszeiten:{"\n"}</Text>
      <Grid container="true" mx={2} my={2}>
      <Grid item="true" xs={2}>
      <Text style={styles.normalText} marginLeft= {2}>Mo: {"\n"}
      Di:{"\n"}
      Mi:{"\n"}
      Do:{"\n"}
      Fr:{"\n"}
      Sa:{"\n"}</Text></Grid>
      <Grid item="true" xs={10}>
      <Text style={styles.normalText} marginLeft= {0}>XX:XX - XX:XX Uhr {"\n"}
      XX:XX - XX:XX Uhr{"\n"}
      XX:XX - XX:XX Uhr{"\n"}
      XX:XX - XX:XX Uhr{"\n"}
      XX:XX - XX:XX Uhr{"\n"}
      XX:XX - XX:XX Uhr{"\n"}</Text></Grid></Grid>
    </Box>
  )
}

export default OpeningTimes
