import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import supabase from "../../../config/supabaseClient";
import { Text } from "react-native-web";
import { styles } from "../../../styles";
import { Box, Paper } from "@mui/material";
import { padding } from "@mui/system";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const GraphVarietySurvey = () => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Stimmen",
        data: [],
        backgroundColor: "#1e4d1f",
      },
    ],
  });

  const [loading, setLoading] = useState(true);

  const fetchSurveyData = async () => {
    try {
      const { data: latestSurveyData, error: latestSurveyError } =
        await supabase
          .from("misc")
          .select("content")
          .eq("keyword", "Sortenumfrage")
          .order("created_at", { ascending: false })
          .limit(1);
  
      if (latestSurveyError) {
        console.error("Fehler beim Abrufen der neuesten Sortenumfrage:", latestSurveyError.message);
        return;
      }
  
      if (latestSurveyData && latestSurveyData.length > 0) {
        const surveyContent = latestSurveyData[0]?.content || {};
  
        // Filtere spezielle Schlüssel, die nicht zur Sortenliste gehören
        const excludedKeys = new Set(["Umfragestart", "Umfrageende", "ParticipantsIDHybrid", "ParticipantsIDSativa", "ParticipantsIDIndica"]);
        const surveyResults = Object.entries(surveyContent)
          .filter(([key]) => !excludedKeys.has(key)) // Entfernen wir den Filterwert != ""
          .sort(([, a], [, b]) => b - a);
  
        const labels = surveyResults.map(([key]) => key);
        const votes = surveyResults.map(([, value]) => Number(value) || 0);
        //console.log("Labels and votes:", labels, votes);
        setData({
          labels,
          datasets: [
            {
              label: "Stimmen",
              data: votes,
              backgroundColor: "#2f5e32",
            },
          ],
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Allgemeiner Fehler beim Laden der Daten:", error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchSurveyData();
  }, []);

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
        barThickness: 30, // Hier kannst du weiterhin die Dicke anpassen
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Dies kann helfen, mehr Platz für Labels zu schaffen
    plugins: {
      legend: {
        position: 'top',
        align: 'center'
      },
      tooltip: {
        mode: 'nearest', // Änderung hier: von 'index' zu 'nearest'
        intersect: true, // Stellen Sie sicher, dass nur der Tooltip für den Balken angezeigt wird, über den die Maus fährt
      },
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          maxRotation: 0, // Hier kannst du die Rotation der Labels anpassen
          minRotation: 0, // Rotation auf 90 Grad für eine bessere Sichtbarkeit
          padding: 2, // Padding zwischen Balken und Labels (wirkt sich nicht auf den Abstand zwischen Labels aus)
          font: {
            size: 14, // Anpassung der Schriftgröße
          }
        },
        maxBarThickness: 35,
        categoryPercentage: 0.8,
        barPercentage: 0.9, // Anpassen für breitere Balken
      },
    },
  };
  
  const minHeight = 400; // Minimale Höhe des Diagrammcontainers
const heightPerLabel = 55; // Höhe pro Label

const dynamicHeight = Math.max(minHeight, data.labels.length * heightPerLabel);

  return (
    <div>
      <Box
        component={Paper}
        sx={{
          padding: 2,
          paddingBottom: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxHeight: 1500,
          height: `${dynamicHeight}px`, // Dynamische Höhe basierend auf der Anzahl der Labels
        }}
      >
        <Text style={styles.titleText} margin="auto" paddingBottom="5">
          Ergebnisse: Sortenumfrage
        </Text>
        <Bar data={data} options={options}></Bar>
      </Box>
    </div>
  );
};

export default GraphVarietySurvey;