import * as React from "react";
import supabase from "../../../config/supabaseClient.js";
import { useState, useEffect, stringify } from "react";
import dayjs from "dayjs";

import Button from "@mui/material/Button";
import { TextField, capitalize, Typography, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function ParticipateInRatingSurveyDialog(props) {
  const { UserData } = props;
  const oldSelection = UserData[0].LastVarietyVote;
  const [dialogtype, setDialogType] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState(null);
  const [possiblePlants, setPossiblePlants] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState("");
  const [plantData, setPlantData] = useState(null);
  const [allPlants, setAllPlants] = useState([]);
  const [participatedHybrid, setParticipatedHybrid] = useState(false);
  const [participatedIndica, setParticipatedIndica] = useState(false);
  const [participatedSativa, setParticipatedSativa] = useState(false);
  const [participated, setParticipated] = useState(false);
  const [ratedPlantnames, setRatedPlantnames] = useState([]);
  const [displayStrings, setDisplayStrings] = useState([]);
  const [TasteRating, setTasteRating] = useState("");
  const [EffectRating, setEffectRating] = useState("");
  const [wrongRole, setWrongRole] = useState(false);
  const [latestSurvey, setLatestSurvey] = useState(null)
  
  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handlePlantSelect = (plantname) => {
    setSelectedPlant(plantname);
    //console.log(selectedPlant);
  };

  const handleTasteRatingSelect = (plantname) => {
    setTasteRating(plantname);
    //console.log(selectedPlant);
  };

  const handleEffectRatingSelect = (plantname) => {
    setEffectRating(plantname);
    //console.log(selectedPlant);
  };

  useEffect(() => {
    if (UserData[0].role === "Registriert" || UserData[0].role === "Bann") {
      //console.log("1",UserData[0].role, "Falsche Rolle? ", wrongRole);
      setWrongRole(true);
    } else {
      setWrongRole(false);
      //console.log("2",UserData[0].role, "Falsche Rolle? ", wrongRole);
    }
  }, []);


  useEffect(() => {
    const MemberParticipated = async () => {
      try {
        const { data: latestSurvey, error: latestSurveyError } = await supabase
          .from("misc")
          .select("*")
          .eq("keyword", "Bewertungsumfrage")
          .order("created_at", { ascending: false })
          .limit(1);

        if (!latestSurvey || latestSurvey.length === 0) {
          return true; // Hier kannst du festlegen, wie du vorgehen möchtest, wenn keine Umfrage vorhanden ist
        }

        const participantsIDHybdrid = latestSurvey[0].content?.ParticipantsIDHybrid;
        const participantsIDIndica = latestSurvey[0].content?.ParticipantsIDIndica;
        const participantsIDSativa = latestSurvey[0].content?.ParticipantsIDSativa;

        
        //console.log(participantsIDHybdrid, participantsIDIndica, participantsIDSativa)
        //console.log(participatedHybrid,participatedIndica,participatedSativa,participated)

        if (participantsIDHybdrid && participantsIDHybdrid.includes(UserData[0].id)) {
          setParticipatedHybrid(true);}

        if (participantsIDIndica && participantsIDIndica.includes(UserData[0].id)) {
          setParticipatedIndica(true);}

        if (participantsIDSativa && participantsIDSativa.includes(UserData[0].id)) {
          setParticipatedSativa(true);
          //console.log("is drin")
        } 
        
        if (participantsIDHybdrid.includes(UserData[0].id) && participantsIDIndica.includes(UserData[0].id) && participantsIDSativa.includes(UserData[0].id)){
          setParticipated(true)
        }
        //console.log(participatedHybrid,participatedIndica,participatedSativa,participated)
      } catch (error) {
        console.error("Fehler beim Abrufen der Umfrage:", error);
        // Handle error appropriately
        return false;
      }
    };
    MemberParticipated();
  }, []);

  //console.log(surveyInfo.surveyData.Hybrid)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Finde die neueste Umfrage mit dem Keyword "Bewertungsumfrage"
        const { data: latestSurvey, error: latestSurveyError } = await supabase
          .from("misc")
          .select("id, keyword, content")
          .eq("keyword", "Bewertungsumfrage")
          .order("created_at", { ascending: false })
          .limit(1);

        if (latestSurveyError) {
          console.error(
            "Fehler beim Abrufen der neuesten Bewertungsumfrage:",
            latestSurveyError.message
          );
          return null;
        }

        if (!latestSurvey || latestSurvey.length === 0) {
          console.log("Keine Bewertungsumfrage gefunden.");
          return null;
        }

        const survey = latestSurvey[0];
        const surveyTitel = `${survey.keyword} ${survey.content.Umfragestart} - ${survey.content.Umfrageende}`;

        setSurveyInfo({
          surveyTitel,
          surveyData: survey.content,
        });
        //console.log(survey.content.ParticipantsIndica)
      } catch (error) {
        console.error("Allgemeiner Fehler:", error.message);
        return null;
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const getPlantsData = async () => {
      try {
        // Finde alle Pflanzen mit dem Keyword "Plant"
        const { data: allPlants, error: plantsError } = await supabase
          .from("misc")
          .select("content")
          .eq("keyword", "Plant");

        if (plantsError) {
          console.error(
            "Fehler beim Abrufen der Pflanzen:",
            plantsError.message
          );
          return null;
        }

        const allPlantnames = allPlants.map(
          (plant) => plant.content?.plantname
        );

        setAllPlants(allPlants);
      } catch (error) {
        console.error("Allgemeiner Fehler:", error.message);
        return null;
      }
    };
    getPlantsData();
  }, []);

  const handleParticipateInSurvey = async () => {
    try {
      // Überprüfe, ob eine Pflanze ausgewählt wurde
      if (!selectedPlant) {
        console.error("Es wurde keine Pflanze ausgewählt.");
        return;
      }

      // Finde den neuesten Eintrag mit dem Keyword "Bewertungsumfrage"
      const { data: latestSurvey, error: latestSurveyError } = await supabase
        .from("misc")
        .select("*")
        .eq("keyword", "Bewertungsumfrage")
        .order("created_at", { ascending: false })
        .limit(1);
      //console.log("Latest Survey:", latestSurvey);
      if (latestSurveyError) {
        console.error(
          "Fehler beim Abrufen der neuesten Bewertungsumfrage:",
          latestSurveyError.message
        );
        return;
      }

      if (!latestSurvey || latestSurvey.length === 0) {
        console.error("Keine Bewertungsumfrage gefunden.");
        return;
      }

      const latestSurveyContent = latestSurvey[0]?.content;
      //console.log("Latest Survey Content:", latestSurveyContent.ParticipantsSativa);

      // Erhöhe den Wert in der Spalte content mit dem letzten Eintrag Sortenumfrage
      // um 1 für die ausgewählte Pflanze
      if (selectedPlant === surveyInfo?.surveyData?.Hybrid) {
        const currentParticipants =
          latestSurveyContent.ParticipantsHybrid || 0; //vorher latestSurveyContent[surveyInfo?.surveyData?.ParticipantsHybrid]
        const newParticipants = currentParticipants + 1;

        const currentTasteRating =
          latestSurveyContent.TasteRatingHybrid || 0; //vorher latestSurveyContent[surveyInfo?.surveyData?.TasteRatingHybrid]
        const overallTasteRating = currentTasteRating * currentParticipants;
        const newTasteRating =
          (Math.min(overallTasteRating) + Math.min(TasteRating)) / newParticipants;

        const currentEffectRating =
          latestSurveyContent.EffectRatingHybrid || 0; //vorher latestSurveyContent[surveyInfo?.surveyData?.EffectRatingHybrid]
        const overallEffectRating = currentEffectRating * currentParticipants;
        const newEffectRating =
          (Math.min(overallEffectRating) + Math.min(EffectRating)) / newParticipants;

        const { error: updateParticipantsError } = await supabase
          .from("misc")
          .update([
            {
              content: {
                ...latestSurveyContent,
                TasteRatingHybrid: newTasteRating.toFixed(2),
                EffectRatingHybrid: newEffectRating.toFixed(2),
                ParticipantsHybrid: newParticipants,
                ParticipantsIDHybrid: [
                  ...latestSurveyContent.ParticipantsIDHybrid,
                  UserData[0].id,
                ],
              },
            },
          ])
          .eq("id", latestSurvey[0].id);

        if (updateParticipantsError) {
          console.error(
            "Fehler beim Hinzufügen des Benutzers zur Sortenumfrage:",
            updateParticipantsError.message
          );
          return;
        }

        // Trage den Wert von selectedPlant in die Tabelle User in die Spalte LastVarietyVote der jeweiligen UserID ein
        const { error: updateUserError } = await supabase
          .from("User")
          .update({
            LastHybridTasteRating: TasteRating,
            LastHybridEffectRating: EffectRating,
          })
          .eq("id", UserData[0].id);

        if (updateUserError) {
          console.error(
            "Fehler beim Aktualisieren des Benutzers:",
            updateUserError.message
          );
          return;
        }
      } else if (selectedPlant === surveyInfo?.surveyData?.Indica) {
        const currentParticipants =
          latestSurveyContent.ParticipantsIndica || 0; //vorher latestSurveyContent[surveyInfo?.surveyData?.ParticipantsIndica]
        const newParticipants = currentParticipants + 1;
      
        const currentTasteRating =
          latestSurveyContent.TasteRatingIndica || 0; //vorher latestSurveyContent[surveyInfo?.surveyData?.TasteRatingIndica]
        const overallTasteRating = currentTasteRating * currentParticipants;
        const newTasteRating =
          (Math.min(overallTasteRating) + Math.min(TasteRating)) / newParticipants;
      
        const currentEffectRating =
          latestSurveyContent.EffectRatingIndica || 0; //vorher latestSurveyContent[surveyInfo?.surveyData?.EffectRatingIndica]
        const overallEffectRating = currentEffectRating * currentParticipants;
        const newEffectRating =
          (Math.min(overallEffectRating) + Math.min(EffectRating)) / newParticipants;
      
        const { error: updateParticipantsError } = await supabase
          .from("misc")
          .update([
            {
              content: {
                ...latestSurveyContent,
                TasteRatingIndica: newTasteRating.toFixed(2),
                EffectRatingIndica: newEffectRating.toFixed(2),
                ParticipantsIndica: newParticipants,
                ParticipantsIDIndica: [
                  ...latestSurveyContent.ParticipantsIDIndica,
                  UserData[0].id,
                ],
              },
            },
          ])
          .eq("id", latestSurvey[0].id);
      
        if (updateParticipantsError) {
          console.error(
            "Fehler beim Hinzufügen des Benutzers zur Sortenumfrage:",
            updateParticipantsError.message
          );
          return;
        }
      
        // Trage den Wert von selectedPlant in die Tabelle User in die Spalte LastVarietyVote der jeweiligen UserID ein
        const { error: updateUserError } = await supabase
          .from("User")
          .update({
            LastIndicaTasteRating: TasteRating,
            LastIndicaEffectRating: EffectRating,
          })
          .eq("id", UserData[0].id);
      
        if (updateUserError) {
          console.error(
            "Fehler beim Aktualisieren des Benutzers:",
            updateUserError.message
          );
          return;
        }
      } else if (selectedPlant === surveyInfo?.surveyData?.Sativa) {
        const currentParticipants =
        latestSurveyContent.ParticipantsSativa || 0; //vorher latestSurveyContent[surveyInfo?.surveyData?.ParticipantsSativa]
        const newParticipants = currentParticipants + 1;
      
        const currentTasteRating =
          latestSurveyContent.TasteRatingSativa || 0; //vorher latestSurveyContent[surveyInfo?.surveyData?.TasteRatingSativa]
        const overallTasteRating = currentTasteRating * currentParticipants;
        const newTasteRating =
          (Math.min(overallTasteRating) + Math.min(TasteRating)) / newParticipants;
      
        const currentEffectRating =
          latestSurveyContent.EffectRatingSativa || 0; //vorher latestSurveyContent[surveyInfo?.surveyData?.EffectRatingSativa]
        const overallEffectRating = currentEffectRating * currentParticipants;
        const newEffectRating =
          (Math.min(overallEffectRating) + Math.min(EffectRating)) / newParticipants;
      
        const { error: updateParticipantsError } = await supabase
          .from("misc")
          .update([
            {
              content: {
                ...latestSurveyContent,
                TasteRatingSativa: newTasteRating.toFixed(2),
                EffectRatingSativa: newEffectRating.toFixed(2),
                ParticipantsSativa: newParticipants,
                ParticipantsIDSativa: [
                  ...latestSurveyContent.ParticipantsIDSativa,
                  UserData[0].id,
                ],
              },
            },
          ])
          .eq("id", latestSurvey[0].id);
      
        if (updateParticipantsError) {
          console.error(
            "Fehler beim Hinzufügen des Benutzers zur Sortenumfrage:",
            updateParticipantsError.message
          );
          return;
        }
      
        
        // Trage den Wert von selectedPlant in die Tabelle User in die Spalte LastVarietyVote der jeweiligen UserID ein
        const { error: updateUserError } = await supabase
          .from("User")
          .update({
            LastSativaTasteRating: TasteRating,
            LastSativaEffectRating: EffectRating,
          })
          .eq("id", UserData[0].id);
      
        if (updateUserError) {
          console.error(
            "Fehler beim Aktualisieren des Benutzers:",
            updateUserError.message
          );
          return;
        }
      } else {
        // Handhabung, wenn keine der Bedingungen erfüllt ist
        console.error("Ungültige Pflanzenauswahl.");
      }
      //console.log("Teilnahme erfolgreich abgeschlossen!");
    } catch (error) {
      console.error("Allgemeiner Fehler:", error.message);
    }
    handleCloseDialog();
    window.location.reload(false);
  };

  const editAnswer = async () => {
    try {} catch (error) {
      console.error("Allgemeiner Fehler:", error.message);
    }
    handleCloseDialog();
    window.location.reload(false);
  };

  return (
    <React.Fragment>
      <Button
        color="success"
        className="button"
        variant="contained"
        onClick={() => {
          handleClickOpen();
          setDialogType("Teilnehmen");
        }}
        disabled={participated || wrongRole}
      >
        Teilnehmen
      </Button>
{/*       <Button
        className="button"
        variant="contained"
        onClick={() => {
          handleClickOpen();
          setDialogType("Antwort bearbeiten");
        }}
        disabled={!participated}
      >
        Antwort bearbeiten
      </Button> */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle> {!surveyInfo || surveyInfo.length === 0 ? "Keine Bewertungsumfrage vorhanden": surveyInfo?.surveyTitel }</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            {dialogtype === "Antwort bearbeiten" && (
              <Typography variant="body1" marginBottom={2}>
                Deine alte Auswahl: {UserData[0].LastVarietyVote}
              </Typography>
            )}
            <Select
              value={selectedPlant}
              onChange={(e) => handlePlantSelect(e.target.value)}
            >
              <MenuItem value={surveyInfo?.surveyData?.Hybrid} disabled={participatedHybrid}>
                {surveyInfo?.surveyData?.Hybrid}
              </MenuItem>
              <MenuItem value={surveyInfo?.surveyData?.Indica} disabled={participatedIndica}>
                {surveyInfo?.surveyData?.Indica}
              </MenuItem>
              <MenuItem value={surveyInfo?.surveyData?.Sativa} disabled={participatedSativa}>
                {surveyInfo?.surveyData?.Sativa}
              </MenuItem>
            </Select>
            {selectedPlant && (
              <Box padding={2}>
                <Typography variant="h6">Herstellerangaben:</Typography>
                {allPlants.map((plant) => {
                  if (plant.content?.plantname === selectedPlant) {
                    // Extrahiere die gewünschten Informationen aus der content-Zelle
                    const {
                      CBD,
                      THC,
                      type,
                      Taste,
                      Effect,
                      extras,
                      BloomDuration,
                    } = plant.content;

                    return (
                      <Box
                        key={selectedPlant}
                        sx={{
                          maxWidth: 400,
                          padding: 2,
                          display: "flex",
                          alignItems: "left",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="body1">
                          Geschmack: {Taste.join(", ")}
                        </Typography>
                        <FormControl>
                          <Select
                            value={TasteRating}
                            onChange={(e) =>
                              handleTasteRatingSelect(e.target.value)
                            }
                          >
                            <MenuItem value="1">
                              1 - Trifft gar nicht zu
                            </MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3 - Trifft teilweise zu</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5 - Trifft voll zu</MenuItem>
                          </Select>
                        </FormControl>

                        <FormControl>
                          <Typography variant="body1" sx={{ marginTop: 2 }}>
                            Effekt: {Effect.join(", ")}
                          </Typography>
                          <Select
                            value={EffectRating}
                            onChange={(e) =>
                              handleEffectRatingSelect(e.target.value)
                            }
                          >
                            <MenuItem value="1">
                              1 - Trifft gar nicht zu
                            </MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3 - Trifft teilweise zu</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5 - Trifft voll zu</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    );
                  }
                  return null;
                })}
              </Box>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Schließen</Button>
          <Button
            onClick={() => {
              if (dialogtype === "Antwort bearbeiten") {
                editAnswer(oldSelection, selectedPlant); // Funktion aufrufen, wenn dialogtype "Antwort bearbeiten" ist
              } else {
                handleParticipateInSurvey(); // Andernfalls handleParticipateInSurvey aufrufen
              }
            }}
          >
            Absenden
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
